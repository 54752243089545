import React, { memo, useCallback } from 'react';
import {
    GanttStripDto,
    KeyGetGetAllSlotRequests,
    KeyGetGetDayGanttTurnarounds,
    KeyGetGetSlotStrip,
    PostCancelSlotOccurrencesResponse,
    UpdatePeriodDto,
    useMutationPostCancelSlotOccurrences,
    useQueryGetGetSlotStrip,
} from '@models';
import { CancelPopin } from '@components/Occurences/CancelPopin/CancelPopin';
import { QueryClient, useQueryClient } from 'react-query';
import { useSetUi, useUiValue } from '../../../contexts/UiContext';
import { format } from 'date-fns';

const onMutateRequests =
    (queryClient: QueryClient) =>
    ({ data }: PostCancelSlotOccurrencesResponse) => {
        if (!data || !data.updatedSlotId) {
            return;
        }

        queryClient.invalidateQueries(KeyGetGetDayGanttTurnarounds);
        queryClient.invalidateQueries(KeyGetGetSlotStrip);
        queryClient.invalidateQueries(KeyGetGetAllSlotRequests);
    };

export const CancelSlot = memo(function CancelSlot({ slot, onClose }: { slot: GanttStripDto; onClose: () => void }) {
    const queryClient = useQueryClient();
    const setSelectedSlotId = useSetUi('selectedSlotId');
    const setSelectedFlightId = useSetUi('selectedFlightId');

    const selectedDate = useUiValue('selectedDate');

    const { refetch: getSlotStrip } = useQueryGetGetSlotStrip(
        { slotId: slot?.id || '' },
        { enabled: false, queryKey: [KeyGetGetSlotStrip, slot?.id, 'StripMenuItemCancelOccurrences'] },
    );

    // reload strip whenever slot id has changed
    const refetchStrip = useCallback(() => {
        getSlotStrip().then(({ data }) => {
            if (!data) {
                return;
            }

            const firstFlight = (data.data.flights || []).find(
                flight =>
                    flight?.day && format(new Date(flight?.day), 'MM/dd/yyyy') === format(selectedDate, 'MM/dd/yyyy'),
            );
            if (firstFlight) {
                setSelectedFlightId(firstFlight?.id || null);
            }
        });
    }, [getSlotStrip, setSelectedFlightId, selectedDate]);

    // cancel all
    const { mutateAsync: cancelAllSlots, isLoading } = useMutationPostCancelSlotOccurrences({
        onSuccess: onMutateRequests(queryClient),
    });

    return (
        <CancelPopin
            slot={slot}
            onClose={onClose}
            onSubmit={(values: UpdatePeriodDto) => {
                cancelAllSlots({
                    id: slot.id,
                    updatePeriodDto: {
                        begin: values.begin,
                        end: values.end,
                        pattern: values.pattern,
                        punctual: slot.punctual,
                    },
                }).then(({ data }) => {
                    onClose();

                    if (data?.updatedSlotId) {
                        setSelectedSlotId(data.updatedSlotId);
                        refetchStrip();
                    }
                });
            }}
            isLoading={isLoading}
        />
    );
});
