import { StripMenu } from '@components/Strip/StripMenu/StripMenu';
import React, { memo } from 'react';
import { StripMenuItemConfirmAllOccurrences } from '@components/Strip/StripMenu/StripMenuItemConfirmAllOccurrences';
import { StripMenuItemSeeRequestView } from '@components/Strip/StripMenu/StripMenuItemSeeRequestView';
import { StripMenuItemModifyAllOccurrences } from '@components/Strip/StripMenu/StripMenuItemModifyAllOccurrences';
import { StripMenuItemSplitArrDep } from '@components/Strip/StripMenu/StripMenuItemSplitArrDep';
import { StripMenuItemCancelOccurrences } from '@components/Strip/StripMenu/StripMenuItemCancelOccurrences';
import { StripMenuItemAssociation } from '@components/Strip/StripMenu/StripMenuItemAssociation';
import { useBoolean } from '../../hooks/useBoolean';
import { ModifySlot } from '@components/ModifySlot/ModifySlot';
import { StripMenuItemSeeUpdateHistory } from '@components/Strip/StripMenu/StripMenuItemSeeUpdateHistory';
import { SlotHistory } from '@components/SlotHistory/SlotHistory';
import { Divider } from '@mui/material';
import { StripMenuItemAssociationDayOnly } from '@components/Strip/StripMenu/StripMenuItemAssociationDayOnly';
import { AccessPermissions, GanttStripDto } from '@types';
import { CancelSlot } from '@components/Occurences/CancelPopin/CancelSlot';
import { useCurrentUserProfilePermissions } from '../../contexts/AuthContext.selectors';

export const StripMenuSchedulePage = memo(
    ({
        slot,
        disabled,
        hasPending,
        onClickAssociate,
        onClickAssociateDayOnly,
    }: {
        slot: GanttStripDto;
        disabled?: boolean;
        hasPending?: boolean;
        onClickAssociate?: () => void;
        onClickAssociateDayOnly?: () => void;
    }) => {
        const permissions = useCurrentUserProfilePermissions();
        const canGoToRequests = permissions && permissions.includes(AccessPermissions.REQUEST_PAGE);
        const hasSlotManagementPermission = permissions && permissions.includes(AccessPermissions.SLOT_MANAGEMENT);

        const {
            value: isModifySlotPopinOpened,
            setFalse: closeModifySlotPopin,
            setTrue: openModifySlotPopin,
        } = useBoolean();

        const {
            value: isCancelSlotPopinOpened,
            setFalse: closeCancelSlotPopin,
            setTrue: openCancelSlotPopin,
        } = useBoolean();

        const {
            value: isSlotHistoryPopinOpened,
            setFalse: closeSlotHistoryPopin,
            setTrue: openSlotHistoryPopin,
        } = useBoolean();

        if (!slot) {
            return null;
        }

        return (
            <>
                <StripMenu disabled={disabled}>
                    {canGoToRequests && <StripMenuItemSeeRequestView slot={slot} />}
                    <StripMenuItemSeeUpdateHistory onClick={openSlotHistoryPopin} />
                    {hasSlotManagementPermission && <Divider />}
                    {hasPending && hasSlotManagementPermission && <StripMenuItemConfirmAllOccurrences slot={slot} />}
                    {/*Menu component does not accept jsx fragments as children*/}
                    {hasSlotManagementPermission && <StripMenuItemModifyAllOccurrences onClick={openModifySlotPopin} />}
                    {hasSlotManagementPermission && <StripMenuItemCancelOccurrences onClick={openCancelSlotPopin} />}
                    {hasSlotManagementPermission && <StripMenuItemSplitArrDep slot={slot} />}
                    {hasSlotManagementPermission && <StripMenuItemAssociation slot={slot} onClick={onClickAssociate} />}
                    {hasSlotManagementPermission && (
                        <StripMenuItemAssociationDayOnly slot={slot} onClick={onClickAssociateDayOnly} />
                    )}
                </StripMenu>

                {isModifySlotPopinOpened && <ModifySlot slot={slot} onClose={closeModifySlotPopin} />}
                {isCancelSlotPopinOpened && <CancelSlot slot={slot} onClose={closeCancelSlotPopin} />}
                {isSlotHistoryPopinOpened && <SlotHistory slotId={slot.id!} onClose={closeSlotHistoryPopin} />}
            </>
        );
    },
);
