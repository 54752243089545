import {
    KeyGetGetAllSlotRequests,
    KeyGetGetDayGanttTurnarounds,
    KeyGetGetSlotFilters,
    KeyGetGetSlotStrip,
    ModificationDemandDto,
    useMutationPostCreateSlot,
} from '@models';
import React, { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { ModifySlotOverlapToast } from '@components/ModifySlot/ModifySlotOverlapToast';
import { useQueryClient } from 'react-query';
import { ModifySlotPopin } from '@components/ModifySlot/ModifySlotPopin';
import { errorToastConfig, slotsOverlapErrorToastConfig, successToastConfig } from 'config/constants';

export const RequestAddModifySlotPopin = memo(function AddRequestModifySlotPopin({
    onClose,
}: {
    onClose?: () => void;
}) {
    const intl = useIntl();

    const queryClient = useQueryClient();

    const { mutateAsync: createRequest } = useMutationPostCreateSlot({
        onSuccess: () => {
            queryClient.invalidateQueries(KeyGetGetAllSlotRequests);
            queryClient.invalidateQueries(KeyGetGetSlotFilters);
            queryClient.removeQueries(KeyGetGetDayGanttTurnarounds);
            queryClient.removeQueries(KeyGetGetSlotStrip);
            toast(intl.formatMessage({ id: 'requestForm.success.create' }), successToastConfig);
        },
        onError: ({ response }) => {
            if (response?.data.errorKey === 'SLOTS_OVERLAY') {
                toast(<ModifySlotOverlapToast overlappedSlotId={response.data.slotId} />, slotsOverlapErrorToastConfig);
            } else {
                toast(
                    intl.formatMessage(
                        {
                            id: `requestForm.error.${response?.data.errorKey}`,
                            defaultMessage: intl.formatMessage({ id: 'requestForm.error.default' }),
                        },
                        { message: response?.data.message || response?.data.errorKey || '' },
                    ),
                    errorToastConfig,
                );
            }
        },
    });

    const onModifySlot = useCallback(
        (values: ModificationDemandDto, isConfirm?: boolean) => {
            if (isConfirm) {
                createRequest({ scheduleSlotUpdateDto: values, confirm: true }).then(onClose);
            } else {
                createRequest({ scheduleSlotUpdateDto: values, confirm: false }).then(onClose);
            }
        },
        [createRequest, onClose],
    );

    return <ModifySlotPopin onClose={onClose} onSubmit={onModifySlot} />;
});
