import { SquareButton } from '@components/Button/SquareButton/SquareButton';
import { PopoverMenu } from '@components/navigation/PopoverMenu/PopoverMenu';
import { Box, CircularProgress, MenuItem } from '@mui/material';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as IconPlus } from '../../../assets/icons/icon-plus.svg';
import { useBoolean } from '../../../hooks/useBoolean';
import { ImportTableForm } from './ImportTableForm';
import { SSIMFormPopin } from '@components/Requests/RequestAdd/SSIMFormPopin';
import { RequestAddModifySlotPopin } from '@components/Requests/RequestAdd/RequestAddModifySlotPopin';
import {
    useCurrentUserProfilePermissions,
    useCurrentUserProfileRequestTypes,
} from '../../../contexts/AuthContext.selectors';
import { AccessPermissions, RequestType } from '@types';
import { KeyGetGetAllSlotRequests, KeyGetGetSlotFilters, useMutationPostUploadPartnerFile } from '@models';
import { toast } from 'react-toastify';
import { errorToastConfig, successToastConfig } from '../../../config/constants';
import { useFileImport } from '../../../hooks/useFileImport';
import { useQueryClient } from 'react-query';

const renderButton = ({
    onClick,
    disabled,
}: {
    onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    disabled: boolean;
}) => (
    <SquareButton
        color="secondary"
        size="small"
        variant="contained"
        onClick={onClick}
        startIcon={<IconPlus />}
        loadingPosition="start"
        disabled={disabled}
    >
        <FormattedMessage id="requests.actions.add" />
    </SquareButton>
);

export const RequestAdd = memo(function RequestAddButton() {
    const permissions = useCurrentUserProfilePermissions();
    const hasSlotManagementPermission = permissions && permissions.includes(AccessPermissions.SLOT_MANAGEMENT);
    const { value: openedImportTableForm, setTrue: openImportTableForm, setFalse: closeImportTableForm } = useBoolean();
    const { value: openedModifySlotPopin, setTrue: openModifySlotPopin, setFalse: closeModifySlotPopin } = useBoolean();
    const { value: openedSSIMPopin, setTrue: openSSIMPopin, setFalse: closeSSIMPopin } = useBoolean();

    const queryClient = useQueryClient();

    const profileRequestTypes = useCurrentUserProfileRequestTypes();
    const authorizedManualImport = profileRequestTypes && profileRequestTypes?.includes(RequestType.MANUAL);
    const authorizedBasicImport = profileRequestTypes && profileRequestTypes?.includes(RequestType.IMPORT);
    const authorizedSSIMImport = profileRequestTypes && profileRequestTypes?.includes(RequestType.SSIM);
    const authorizedPartnerImport = profileRequestTypes && profileRequestTypes?.includes(RequestType.PARTNER);

    const { mutateAsync: uploadPartnerFile, isLoading: isLoadingUpload } = useMutationPostUploadPartnerFile({
        onSuccess: ({ data }) => {
            toast(
                <Box>
                    <FormattedMessage
                        id="addRequest.uploadSuccess.impactNewSlotsCreated"
                        values={{ count: data.impactNewSlotsCreated }}
                    />
                    <br />
                    <FormattedMessage
                        id="addRequest.uploadSuccess.impactUpdateSlotsCreated"
                        values={{ count: (data.impactUpdateSlotsCreated || 0) + (data.impactUpdateSlotsUpdated || 0) }}
                    />
                    <br />
                    <FormattedMessage
                        id="addRequest.uploadSuccess.impactUpdateSlotsUpdated"
                        values={{ count: (data.impactCancelSlotsCreated || 0) + (data.impactCancelSlotsUpdated || 0) }}
                    />
                </Box>,
                successToastConfig,
            );
            queryClient.invalidateQueries(KeyGetGetAllSlotRequests);
            queryClient.invalidateQueries(KeyGetGetSlotFilters);
        },
        onError: error => {
            if (error?.response?.data?.errorKey === 'IMPORT_DATA_FORMAT_ERROR') {
                toast(error?.response?.data?.message, errorToastConfig);
            } else {
                toast(<FormattedMessage id={'addRequest.importTable.uploadError'} />, errorToastConfig);
            }
        },
    });

    const { openImportPrompt } = useFileImport({
        onSelectFile: (files: File[]) => {
            uploadPartnerFile({
                file: files[0],
            });
        },
    });

    return (
        <>
            {openedModifySlotPopin && <RequestAddModifySlotPopin onClose={closeModifySlotPopin} />}
            {openedSSIMPopin && <SSIMFormPopin onClose={closeSSIMPopin} />}

            <PopoverMenu
                renderButton={({ onClick }) => renderButton({ onClick, disabled: !hasSlotManagementPermission })}
                onClose={closeImportTableForm}
            >
                {({ close: closeMenu }) =>
                    openedImportTableForm ? (
                        <ImportTableForm onClose={closeMenu} />
                    ) : (
                        // The Menu component doesn't accept a Fragment as a child.
                        [
                            <MenuItem
                                key={'importTable'}
                                onClick={openImportTableForm}
                                disabled={!authorizedBasicImport}
                            >
                                <FormattedMessage id="addRequest.importTable" />
                            </MenuItem>,
                            <MenuItem
                                key={'partnerRequest'}
                                onClick={openImportPrompt}
                                disabled={!authorizedPartnerImport}
                            >
                                <FormattedMessage id="addRequest.partner" />
                                {isLoadingUpload && (
                                    <CircularProgress size={25} color={'secondary'} sx={{ marginLeft: 1 }} />
                                )}
                            </MenuItem>,
                            <MenuItem
                                key={'manualRequest'}
                                onClick={() => {
                                    openModifySlotPopin();
                                    closeMenu();
                                }}
                                disabled={!authorizedManualImport}
                            >
                                <FormattedMessage id="addRequest.manualRequest" />
                            </MenuItem>,
                            <MenuItem
                                key={'ssim'}
                                onClick={() => {
                                    openSSIMPopin();
                                    closeMenu();
                                }}
                                disabled={!authorizedSSIMImport}
                            >
                                <FormattedMessage id="addRequest.ssim" />
                            </MenuItem>,
                        ]
                    )
                }
            </PopoverMenu>
        </>
    );
});
