import React, { memo } from 'react';
import { Box, formGroupClasses, styled, Tooltip, Typography } from '@mui/material';
import { AirportResourcesDto, FlightTurnaroundDto } from '@types';
import { useFormikContext } from 'formik';
import { ResourceFormValues } from '@components/Resources/Update/ResourcesForm/ResourcesFormContainer';
import { FormattedMessage } from 'react-intl';
import { useCurrentUserClient, useCurrentUserResources } from '../../../../contexts/AuthContext.selectors';
import { ResourcesFormFieldsItem } from '@components/Resources/Update/ResourcesForm/ResourcesFormFieldsItem';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { IcaoIata } from '@components/IcaoIata';

const StyledResources = styled(Box)`
    display: flex;
    margin: ${({ theme }) => theme.spacing(4)};
    margin-bottom: 0;
    color: ${({ theme }) => theme.palette.primary.contrastText};
    & .${formGroupClasses.root} + .${formGroupClasses.root} {
        margin-left: 20px;
    }
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;

const StyledTypography = styled(Typography)`
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

interface ResourcesFormFieldsProps {
    resources?: AirportResourcesDto;
    side: 'arrival' | 'departure' | 'both';
    placeholders?: boolean;
    flight: FlightTurnaroundDto;
}

export const ResourcesFormFields = memo(function ResourcesFields({
    resources,
    side,
    placeholders,
    flight,
}: ResourcesFormFieldsProps) {
    const userResources = useCurrentUserResources();
    const rms = useCurrentUserClient()?.rms;

    const { initialValues } = useFormikContext<ResourceFormValues>();

    return (
        <StyledResources>
            <StyledTypography variant={'h5'} alignItems={'center'} display={'flex'} gap={'5px'}>
                {side === 'both' ? (
                    <>
                        {initialValues.sibt && <ResourceFormFieldsHeading flight={flight} side={'arrival'} />}
                        {initialValues.sibt && initialValues.sobt && ' / '}
                        {initialValues.sobt && <ResourceFormFieldsHeading flight={flight} side={'departure'} />}
                    </>
                ) : (
                    <ResourceFormFieldsHeading flight={flight} side={side} />
                )}
            </StyledTypography>

            {userResources?.map(({ origin, type }) => (
                <ResourcesFormFieldsItem
                    origin={origin}
                    type={type}
                    side={side}
                    resources={resources}
                    placeholders={placeholders}
                    rms={rms}
                />
            ))}
        </StyledResources>
    );
});

interface ResourceFormFieldsHeadingProps {
    flight: FlightTurnaroundDto;
    side: 'arrival' | 'departure';
}

function ResourceFormFieldsHeading({ flight, side }: ResourceFormFieldsHeadingProps) {
    const flNum = side === 'arrival' ? flight.arrivalFlightNumberFull : flight.departureFlightNumberFull;
    const icaoIata = side === 'arrival' ? flight.adep : flight.ades;
    return (
        <>
            {side === 'departure' && <FormattedMessage id={'requestForm.departure'} />}
            <Tooltip
                title={
                    <>
                        {flNum} <IcaoIata {...icaoIata} />
                    </>
                }
                placement={side === 'arrival' ? 'left' : 'right'}
            >
                <InfoOutlinedIcon />
            </Tooltip>
            {side === 'arrival' && <FormattedMessage id={'requestForm.arrival'} />}
        </>
    );
}
