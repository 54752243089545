import { Box, styled } from '@mui/material';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_HELP, ROUTE_HOME, ROUTE_REQUESTS, ROUTE_SCHEDULE, ROUTE_SETTINGS } from 'routes';
import { ReactComponent as ClosePopup } from '../../../assets/icons/close-popup.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/icon-logout.svg';
import { ReactComponent as RequestsIcon } from '../../../assets/icons/icon-requests.svg';
import { ReactComponent as ScheduleIcon } from '../../../assets/icons/icon-schedule.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/icon-settings.svg';
import { useAuth, useCurrentUser, useCurrentUserProfilePermissions } from '../../../contexts/AuthContext.selectors';
import { useUiValue } from '../../../contexts/UiContext';
import { useGoToSchedule } from '../../../hooks/useGoToSchedule';
import { HelpIcon } from '@components/icons/HelpIcon';
import { AccessPermissions } from '@types';

const MenuHeader = styled('div')`
    height: 90px;
    width: 100%;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 33px 26px;
    font-family: Roboto, serif;
    font-size: 28px;
    font-weight: 300;
    line-height: 33px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.blue.darkest};
`;

const MenuContent = styled('div')`
    font-family: Roboto, serif;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0;
`;

const MenuItem = styled('div')<{ selected?: boolean; disabled?: boolean }>`
    display: flex;
    align-items: center;
    padding: 10px 25px;
    height: 72px;
    cursor: pointer;
    box-sizing: content-box;
    ${({ selected }) => (selected ? 'background: #ffffff30;' : '')}
    ${({ disabled }) => (disabled ? 'opacity: 0.5; cursor: default' : '')}
`;

const IconContainer = styled('div')`
    margin-right: 15px;
`;

const StyledMenu = styled('div')`
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background: ${({ theme }) => theme.palette.blue.menuBg};
`;

export const Menu = memo(({ closeMenu }: { closeMenu?: () => void }) => {
    const navigate = useNavigate();
    const goToSchedule = useGoToSchedule();
    const currentUser = useCurrentUser();
    const selectedDate = useUiValue('selectedDate');
    const logout = useAuth('logout');
    const location = useLocation();
    const permissions = useCurrentUserProfilePermissions();
    const canGoToSchedule = permissions && permissions.includes(AccessPermissions.GANTT_PAGE);
    const canGoToRequests = permissions && permissions.includes(AccessPermissions.REQUEST_PAGE);

    return (
        <StyledMenu>
            <MenuHeader>
                <Box>
                    {currentUser?.firstName}&nbsp;{currentUser?.lastName}
                </Box>
                <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
                    <ClosePopup onClick={closeMenu} />
                </Box>
            </MenuHeader>
            <MenuContent onClick={closeMenu}>
                <MenuItem
                    onClick={() => canGoToSchedule && goToSchedule(selectedDate)}
                    selected={
                        !!matchPath(ROUTE_SCHEDULE, location.pathname) || !!matchPath(ROUTE_HOME, location.pathname)
                    }
                    disabled={!canGoToSchedule}
                >
                    <IconContainer>
                        <ScheduleIcon width="38" height="38" color="white" />
                    </IconContainer>
                    <FormattedMessage id="menu.schedule" />
                </MenuItem>
                <MenuItem
                    onClick={() => canGoToRequests && navigate(ROUTE_REQUESTS)}
                    selected={!!matchPath(ROUTE_REQUESTS, location.pathname)}
                    disabled={!canGoToRequests}
                >
                    <IconContainer>
                        <RequestsIcon width="38" height="38" color="white" />
                    </IconContainer>
                    <FormattedMessage id="menu.requests" />
                </MenuItem>
                <MenuItem
                    onClick={() => navigate(ROUTE_SETTINGS)}
                    selected={!!matchPath(ROUTE_SETTINGS, location.pathname)}
                >
                    <IconContainer>
                        <SettingsIcon width="38" height="38" color="white" />
                    </IconContainer>
                    <FormattedMessage id="menu.settings" />
                </MenuItem>
                <MenuItem onClick={() => navigate(ROUTE_HELP)} selected={!!matchPath(ROUTE_HELP, location.pathname)}>
                    <IconContainer>
                        <HelpIcon size={38} />
                    </IconContainer>
                    <FormattedMessage id="menu.help" />
                </MenuItem>
                <MenuItem onClick={logout}>
                    <IconContainer>
                        <LogoutIcon width="38" height="38" color="white" />
                    </IconContainer>
                    <FormattedMessage id="menu.logout" />
                </MenuItem>
            </MenuContent>
        </StyledMenu>
    );
});
