import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTE_HOME, ROUTE_LOGIN, ROUTE_REQUESTS } from 'routes';
import { useAuth, useCurrentUserProfilePermissions } from '../../../contexts/AuthContext.selectors';
import { AccessPermissions } from '@types';

interface RequireAuthProps {
    children: JSX.Element;
    requiredPermission?: AccessPermissions.REQUEST_PAGE | AccessPermissions.GANTT_PAGE;
}

// @see https://stackblitz.com/github/remix-run/react-router/tree/main/examples/auth?file=src/App.tsx
export function RequireAuth({ children, requiredPermission }: RequireAuthProps) {
    const isLoggedIn = useAuth('isLoggedIn');
    const location = useLocation();
    const permissions = useCurrentUserProfilePermissions();

    if (!isLoggedIn) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than droping them off on the home page.
        return <Navigate to={ROUTE_LOGIN} state={{ from: location }} />;
    }

    if (requiredPermission) {
        const hasRequiredPermission = permissions && permissions?.includes(requiredPermission);
        const navigateTo =
            !hasRequiredPermission && requiredPermission === AccessPermissions.REQUEST_PAGE
                ? ROUTE_HOME
                : ROUTE_REQUESTS;

        if (!hasRequiredPermission) {
            return <Navigate to={navigateTo} state={{ from: location }} />;
        }
    }

    return children;
}
