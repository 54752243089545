/* tslint:disable */
/* eslint-disable */
/**
 * Copyright Innov'ATM all rights reserved. This software is the property of Innov'ATM and may not be used in any
 * manner except under a license agreement signed with Innov'ATM.
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export enum AccessPermissions {
  "REQUEST_PAGE" = "REQUEST_PAGE",
  "GANTT_PAGE" = "GANTT_PAGE",
  "SLOT_MANAGEMENT" = "SLOT_MANAGEMENT",
}
