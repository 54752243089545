import { RoundButton } from '@components/Button/RoundButton/RoundButton';
import { Popin, PopinCloseButton } from '@components/navigation/Popin/Popin';
import { FlightTurnaroundDto, TurnaroundResourcesUpdateDto } from '@models';
import EditIcon from '@mui/icons-material/Edit';
import { Box } from '@mui/material';
import React, { memo, useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
    ResourceFormValues,
    ResourcesFormContainer,
} from '@components/Resources/Update/ResourcesForm/ResourcesFormContainer';
import omit from 'lodash/omit';
import { Formik, useFormikContext } from 'formik';

const SubmitButton = memo(function SubmitButton() {
    const { submitForm } = useFormikContext<TurnaroundResourcesUpdateDto>();
    return (
        <RoundButton onClick={() => submitForm()} variant="contained" size="medium">
            <FormattedMessage id="resourcesForm.confirm" />
        </RoundButton>
    );
});

export const ResourcesPopin = memo(function ResourcesPopin({
    flight,
    onClose,
    onSubmit,
}: {
    flight: FlightTurnaroundDto;
    onClose: () => void;
    onSubmit: (v: { turnaroundResourcesUpdate: TurnaroundResourcesUpdateDto; propagate: boolean }) => void;
}) {
    const initialValues: ResourceFormValues = useMemo(
        () => ({
            ...flight.resources,
            id: flight.id,
            propagate: false,
            sibt: flight.sibt,
            sobt: flight.sobt,
        }),
        [flight],
    );

    const handleSubmit = useCallback(
        (values: ResourceFormValues) => {
            onSubmit?.({
                turnaroundResourcesUpdate: omit(values, ['sibt', 'sobt', 'propagate']),
                propagate: values.propagate,
            });
        },
        [onSubmit],
    );

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <Popin
                onClose={onClose}
                title={
                    <>
                        <EditIcon />
                        <Box display="flex" flex={1}>
                            <FormattedMessage id="resourcesForm.title.single" />
                        </Box>
                        <PopinCloseButton onClose={onClose} />
                    </>
                }
                content={<ResourcesFormContainer flight={flight} />}
                actions={
                    <>
                        <RoundButton onClick={onClose} variant="outlined" size="medium">
                            <FormattedMessage id="resourcesForm.cancel" />
                        </RoundButton>
                        &emsp;
                        <SubmitButton />
                    </>
                }
            />
        </Formik>
    );
});
