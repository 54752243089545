/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Copyright Innov'ATM all rights reserved. This software is the property of Innov'ATM and may not be used in any
 * manner except under a license agreement signed with Innov'ATM.
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ScheduleImportSummaryDto } from '../models';
import { SsimMessage } from '../models';
import { Configuration, ConfigurationParameters } from '../configuration';
import { BASE_PATH, BaseAPI, RequestArgs } from '../base';
import { AxiosInstance, AxiosPromise, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, UseMutationOptions, useMutation, UseQueryOptions } from 'react-query';
import {
    assertParamExists,
    createRequestFunction,
    DUMMY_BASE_URL,
    serializeDataIfNeeded,
    setSearchParams,
    toPathString,
    globalAxios,
    setBearerAuthToObject,
} from '../common';
import { useAppAuthValue } from '../../utils';
export const AirlineFlightScheduleWebServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        exportSchedule: async (
            token: string,
            from: string,
            to: string,
            format: string,
            on?: string,
            since?: string,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('exportSchedule', 'token', token);
            // verify required parameter 'from' is not null or undefined
            assertParamExists('exportSchedule', 'from', from);
            // verify required parameter 'to' is not null or undefined
            assertParamExists('exportSchedule', 'to', to);
            // verify required parameter 'format' is not null or undefined
            assertParamExists('exportSchedule', 'format', format);
            const localVarPath = `/export/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }
            if (from !== undefined) {
                localVarQueryParameter['from'] =
                    (from as any) instanceof Date ? (from as any).toISOString().substr(0, 10) : from;
            }
            if (to !== undefined) {
                localVarQueryParameter['to'] =
                    (to as any) instanceof Date ? (to as any).toISOString().substr(0, 10) : to;
            }
            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }
            if (on !== undefined) {
                localVarQueryParameter['on'] =
                    (on as any) instanceof Date ? (on as any).toISOString().substr(0, 10) : on;
            }
            if (since !== undefined) {
                localVarQueryParameter['since'] =
                    (since as any) instanceof Date ? (since as any).toISOString().substr(0, 10) : since;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        getTokenForScheduleExport: async (
            from: string,
            to: string,
            format: string,
            on?: string,
            since?: string,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getTokenForScheduleExport', 'from', from);
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getTokenForScheduleExport', 'to', to);
            // verify required parameter 'format' is not null or undefined
            assertParamExists('getTokenForScheduleExport', 'format', format);
            const localVarPath = `/export/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (from !== undefined) {
                localVarQueryParameter['from'] =
                    (from as any) instanceof Date ? (from as any).toISOString().substr(0, 10) : from;
            }
            if (to !== undefined) {
                localVarQueryParameter['to'] =
                    (to as any) instanceof Date ? (to as any).toISOString().substr(0, 10) : to;
            }
            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }
            if (on !== undefined) {
                localVarQueryParameter['on'] =
                    (on as any) instanceof Date ? (on as any).toISOString().substr(0, 10) : on;
            }
            if (since !== undefined) {
                localVarQueryParameter['since'] =
                    (since as any) instanceof Date ? (since as any).toISOString().substr(0, 10) : since;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        uploadFlightScheduleFile: async (
            season: string,
            from: string,
            file: any,
            complete?: boolean,
            localTime?: boolean,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'season' is not null or undefined
            assertParamExists('uploadFlightScheduleFile', 'season', season);
            // verify required parameter 'from' is not null or undefined
            assertParamExists('uploadFlightScheduleFile', 'from', from);
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadFlightScheduleFile', 'file', file);
            const localVarPath = `/import/airlineSchedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);
            if (season !== undefined) {
                localVarQueryParameter['season'] = season;
            }
            if (from !== undefined) {
                localVarQueryParameter['from'] =
                    (from as any) instanceof Date ? (from as any).toISOString().substr(0, 10) : from;
            }
            if (complete !== undefined) {
                localVarQueryParameter['complete'] = complete;
            }
            if (localTime !== undefined) {
                localVarQueryParameter['localTime'] = localTime;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        uploadPartnerFile: async (file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadPartnerFile', 'file', file);
            const localVarPath = `/import/partnerSchedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        uploadSsimMessage: async (ssimMessage: SsimMessage, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ssimMessage' is not null or undefined
            assertParamExists('uploadSsimMessage', 'ssimMessage', ssimMessage);
            const localVarPath = `/import/ssim`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(ssimMessage, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
export const AirlineFlightScheduleWebServicesApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = AirlineFlightScheduleWebServicesApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} token
         * @param {string} from
         * @param {string} to
         * @param {string} format
         * @param {string} [on]
         * @param {string} [since]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportSchedule(
            token: string,
            from: string,
            to: string,
            format: string,
            on?: string,
            since?: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportSchedule(
                token,
                from,
                to,
                format,
                on,
                since,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} from
         * @param {string} to
         * @param {string} format
         * @param {string} [on]
         * @param {string} [since]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTokenForScheduleExport(
            from: string,
            to: string,
            format: string,
            on?: string,
            since?: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTokenForScheduleExport(
                from,
                to,
                format,
                on,
                since,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} season
         * @param {string} from
         * @param {any} file
         * @param {boolean} [complete]
         * @param {boolean} [localTime]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFlightScheduleFile(
            season: string,
            from: string,
            file: any,
            complete?: boolean,
            localTime?: boolean,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduleImportSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFlightScheduleFile(
                season,
                from,
                file,
                complete,
                localTime,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {any} file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPartnerFile(
            file: any,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduleImportSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPartnerFile(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {SsimMessage} ssimMessage
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadSsimMessage(
            ssimMessage: SsimMessage,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduleImportSummaryDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadSsimMessage(ssimMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

export const AirlineFlightScheduleWebServicesApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = AirlineFlightScheduleWebServicesApiFp(configuration);
    return {
        exportSchedule(
            token: string,
            from: string,
            to: string,
            format: string,
            on?: string,
            since?: string,
            options?: any,
        ): AxiosPromise<any> {
            return localVarFp
                .exportSchedule(token, from, to, format, on, since, options)
                .then(request => request(axios, basePath));
        },
        getTokenForScheduleExport(
            from: string,
            to: string,
            format: string,
            on?: string,
            since?: string,
            options?: any,
        ): AxiosPromise<string> {
            return localVarFp
                .getTokenForScheduleExport(from, to, format, on, since, options)
                .then(request => request(axios, basePath));
        },
        uploadFlightScheduleFile(
            season: string,
            from: string,
            file: any,
            complete?: boolean,
            localTime?: boolean,
            options?: any,
        ): AxiosPromise<ScheduleImportSummaryDto> {
            return localVarFp
                .uploadFlightScheduleFile(season, from, file, complete, localTime, options)
                .then(request => request(axios, basePath));
        },
        uploadPartnerFile(file: any, options?: any): AxiosPromise<ScheduleImportSummaryDto> {
            return localVarFp.uploadPartnerFile(file, options).then(request => request(axios, basePath));
        },
        uploadSsimMessage(ssimMessage: SsimMessage, options?: any): AxiosPromise<ScheduleImportSummaryDto> {
            return localVarFp.uploadSsimMessage(ssimMessage, options).then(request => request(axios, basePath));
        },
    };
};
export interface AirlineFlightScheduleWebServicesApiExportScheduleRequest {
    readonly token: string;

    readonly from: string;

    readonly to: string;

    readonly format: string;

    readonly on?: string;

    readonly since?: string;
}

export interface AirlineFlightScheduleWebServicesApiGetTokenForScheduleExportRequest {
    readonly from: string;

    readonly to: string;

    readonly format: string;

    readonly on?: string;

    readonly since?: string;
}

export interface AirlineFlightScheduleWebServicesApiUploadFlightScheduleFileRequest {
    readonly season: string;

    readonly from: string;

    readonly file: any;

    readonly complete?: boolean;

    readonly localTime?: boolean;
}

export interface AirlineFlightScheduleWebServicesApiUploadPartnerFileRequest {
    readonly file: any;
}

export interface AirlineFlightScheduleWebServicesApiUploadSsimMessageRequest {
    readonly ssimMessage: SsimMessage;
}

export class AirlineFlightScheduleWebServicesApi extends BaseAPI {
    public exportSchedule(requestParameters: AirlineFlightScheduleWebServicesApiExportScheduleRequest, options?: any) {
        return AirlineFlightScheduleWebServicesApiFp(this.configuration)
            .exportSchedule(
                requestParameters.token,
                requestParameters.from,
                requestParameters.to,
                requestParameters.format,
                requestParameters.on,
                requestParameters.since,
                options,
            )
            .then(request => request(this.axios, this.basePath));
    }
    public getTokenForScheduleExport(
        requestParameters: AirlineFlightScheduleWebServicesApiGetTokenForScheduleExportRequest,
        options?: any,
    ) {
        return AirlineFlightScheduleWebServicesApiFp(this.configuration)
            .getTokenForScheduleExport(
                requestParameters.from,
                requestParameters.to,
                requestParameters.format,
                requestParameters.on,
                requestParameters.since,
                options,
            )
            .then(request => request(this.axios, this.basePath));
    }
    public uploadFlightScheduleFile(
        requestParameters: AirlineFlightScheduleWebServicesApiUploadFlightScheduleFileRequest,
        options?: any,
    ) {
        return AirlineFlightScheduleWebServicesApiFp(this.configuration)
            .uploadFlightScheduleFile(
                requestParameters.season,
                requestParameters.from,
                requestParameters.file,
                requestParameters.complete,
                requestParameters.localTime,
                options,
            )
            .then(request => request(this.axios, this.basePath));
    }
    public uploadPartnerFile(
        requestParameters: AirlineFlightScheduleWebServicesApiUploadPartnerFileRequest,
        options?: any,
    ) {
        return AirlineFlightScheduleWebServicesApiFp(this.configuration)
            .uploadPartnerFile(requestParameters.file, options)
            .then(request => request(this.axios, this.basePath));
    }
    public uploadSsimMessage(
        requestParameters: AirlineFlightScheduleWebServicesApiUploadSsimMessageRequest,
        options?: any,
    ) {
        return AirlineFlightScheduleWebServicesApiFp(this.configuration)
            .uploadSsimMessage(requestParameters.ssimMessage, options)
            .then(request => request(this.axios, this.basePath));
    }
}

// custom hook generation here
export type GetExportScheduleResponse = AxiosResponse<any>;
export const KeyGetExportSchedule = 'GetExportSchedule';
export const useMutationGetExportSchedule = (
    options?: UseMutationOptions<
        GetExportScheduleResponse,
        AxiosError,
        AirlineFlightScheduleWebServicesApiExportScheduleRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        GetExportScheduleResponse,
        AxiosError,
        AirlineFlightScheduleWebServicesApiExportScheduleRequest
    >(
        (args: AirlineFlightScheduleWebServicesApiExportScheduleRequest) =>
            new AirlineFlightScheduleWebServicesApi(new Configuration({ accessToken })).exportSchedule(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetExportSchedule = (
    args: AirlineFlightScheduleWebServicesApiExportScheduleRequest,
    options?: UseQueryOptions<GetExportScheduleResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<GetExportScheduleResponse, AxiosError>(
        [KeyGetExportSchedule, args, options && options.queryKey],
        () =>
            new AirlineFlightScheduleWebServicesApi(new Configuration({ accessToken, ...conf })).exportSchedule(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type GetGetTokenForScheduleExportResponse = AxiosResponse<string>;
export const KeyGetGetTokenForScheduleExport = 'GetGetTokenForScheduleExport';
export const useMutationGetGetTokenForScheduleExport = (
    options?: UseMutationOptions<
        GetGetTokenForScheduleExportResponse,
        AxiosError,
        AirlineFlightScheduleWebServicesApiGetTokenForScheduleExportRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        GetGetTokenForScheduleExportResponse,
        AxiosError,
        AirlineFlightScheduleWebServicesApiGetTokenForScheduleExportRequest
    >(
        (args: AirlineFlightScheduleWebServicesApiGetTokenForScheduleExportRequest) =>
            new AirlineFlightScheduleWebServicesApi(new Configuration({ accessToken })).getTokenForScheduleExport(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetGetTokenForScheduleExport = (
    args: AirlineFlightScheduleWebServicesApiGetTokenForScheduleExportRequest,
    options?: UseQueryOptions<GetGetTokenForScheduleExportResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<GetGetTokenForScheduleExportResponse, AxiosError>(
        [KeyGetGetTokenForScheduleExport, args, options && options.queryKey],
        () =>
            new AirlineFlightScheduleWebServicesApi(
                new Configuration({ accessToken, ...conf }),
            ).getTokenForScheduleExport(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostUploadFlightScheduleFileResponse = AxiosResponse<ScheduleImportSummaryDto>;
export const KeyPostUploadFlightScheduleFile = 'PostUploadFlightScheduleFile';
export const useMutationPostUploadFlightScheduleFile = (
    options?: UseMutationOptions<
        PostUploadFlightScheduleFileResponse,
        AxiosError,
        AirlineFlightScheduleWebServicesApiUploadFlightScheduleFileRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostUploadFlightScheduleFileResponse,
        AxiosError,
        AirlineFlightScheduleWebServicesApiUploadFlightScheduleFileRequest
    >(
        (args: AirlineFlightScheduleWebServicesApiUploadFlightScheduleFileRequest) =>
            new AirlineFlightScheduleWebServicesApi(new Configuration({ accessToken })).uploadFlightScheduleFile(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostUploadFlightScheduleFile = (
    args: AirlineFlightScheduleWebServicesApiUploadFlightScheduleFileRequest,
    options?: UseQueryOptions<PostUploadFlightScheduleFileResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostUploadFlightScheduleFileResponse, AxiosError>(
        [KeyPostUploadFlightScheduleFile, args, options && options.queryKey],
        () =>
            new AirlineFlightScheduleWebServicesApi(
                new Configuration({ accessToken, ...conf }),
            ).uploadFlightScheduleFile(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostUploadPartnerFileResponse = AxiosResponse<ScheduleImportSummaryDto>;
export const KeyPostUploadPartnerFile = 'PostUploadPartnerFile';
export const useMutationPostUploadPartnerFile = (
    options?: UseMutationOptions<
        PostUploadPartnerFileResponse,
        AxiosError,
        AirlineFlightScheduleWebServicesApiUploadPartnerFileRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostUploadPartnerFileResponse,
        AxiosError,
        AirlineFlightScheduleWebServicesApiUploadPartnerFileRequest
    >(
        (args: AirlineFlightScheduleWebServicesApiUploadPartnerFileRequest) =>
            new AirlineFlightScheduleWebServicesApi(new Configuration({ accessToken })).uploadPartnerFile(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostUploadPartnerFile = (
    args: AirlineFlightScheduleWebServicesApiUploadPartnerFileRequest,
    options?: UseQueryOptions<PostUploadPartnerFileResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostUploadPartnerFileResponse, AxiosError>(
        [KeyPostUploadPartnerFile, args, options && options.queryKey],
        () =>
            new AirlineFlightScheduleWebServicesApi(new Configuration({ accessToken, ...conf })).uploadPartnerFile(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostUploadSsimMessageResponse = AxiosResponse<ScheduleImportSummaryDto>;
export const KeyPostUploadSsimMessage = 'PostUploadSsimMessage';
export const useMutationPostUploadSsimMessage = (
    options?: UseMutationOptions<
        PostUploadSsimMessageResponse,
        AxiosError,
        AirlineFlightScheduleWebServicesApiUploadSsimMessageRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PostUploadSsimMessageResponse,
        AxiosError,
        AirlineFlightScheduleWebServicesApiUploadSsimMessageRequest
    >(
        (args: AirlineFlightScheduleWebServicesApiUploadSsimMessageRequest) =>
            new AirlineFlightScheduleWebServicesApi(new Configuration({ accessToken })).uploadSsimMessage(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostUploadSsimMessage = (
    args: AirlineFlightScheduleWebServicesApiUploadSsimMessageRequest,
    options?: UseQueryOptions<PostUploadSsimMessageResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostUploadSsimMessageResponse, AxiosError>(
        [KeyPostUploadSsimMessage, args, options && options.queryKey],
        () =>
            new AirlineFlightScheduleWebServicesApi(new Configuration({ accessToken, ...conf })).uploadSsimMessage(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
