import { Checkbox } from '@components/form/Checkbox/Checkbox';
import { FormikDatePicker } from '@components/form/DatePicker/FormikDatePicker';
import { FormikIcaoIataTextField } from '@components/form/IcaoIataTextField/FormikIcaoIataTextField';
import { FormikTextField } from '@components/form/TextField/FormikTextField';
import { RoundSwitch } from '@components/form/RoundSwitch/RoundSwitch';
import { Select } from '@components/form/Select/Select';
import {
    Box,
    Divider,
    formControlClasses,
    formGroupClasses,
    FormLabel,
    Grid,
    gridClasses,
    inputClasses,
    MenuItem,
    styled,
    ToggleButton,
    ToggleButtonGroup,
} from '@mui/material';
import { setDay } from 'date-fns';
import { Form, useFormikContext } from 'formik';
import * as React from 'react';
import { ChangeEvent, memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as IconLanding } from '../../assets/icons/Icon_Landing.svg';
import { ReactComponent as IconTakeOff } from '../../assets/icons/Icon_TakeOff.svg';
import { EMPTY_ARRAY } from '../../config/constants';
import { useCurrentUserClientTrafficTypes } from '../../contexts/AuthContext.selectors';
import { FormikErrorMessage } from '@components/form/FormikErrorMessage/FormikErrorMessage';
import { FormikTimeField } from '@components/form/TimeField/FormikTimeField';
import { FormattedTime } from '@components/time/FormattedTime';
import { ScheduleSlotUpdateDto, WeekDay } from '@types';

export type RequestPopinFormValues = ScheduleSlotUpdateDto & {
    arrivalFlight: boolean;
    departureFlight: boolean;
    icaoIata: 'icao' | 'iata';
};

const getDay = (dayNumber: number) => setDay(new Date(), dayNumber);

const currentDate = new Date();

const StyledLine = styled(Box)`
    display: flex;
    width: 100%;
    margin-top: ${props => props.theme.spacing(4)};
    & .${formControlClasses.root} {
        width: 100%;
    }
    & .${formGroupClasses.root} + .${formGroupClasses.root} {
        margin-left: 20px;
    }
    & .${gridClasses.root} > .MuiBox-root,
    &
        .${gridClasses.root}
        > .MuiBox-root
        > .${formControlClasses.root},
        &
        .${gridClasses.root}
        > .${formControlClasses.root}
        > .${inputClasses.root},
        &
        .${gridClasses.root}
        > .MuiBox-root
        > .${formControlClasses.root}
        > .${inputClasses.root} {
        width: 100%;
    }
    svg {
        color: white;
    }
`;

const StyledTitle = styled(Box)`
    color: white;
    font-size: 26px;
`;

const StyledLabel = styled(FormLabel)`
    color: #ffffff;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 28px;
    cursor: pointer;
`;

const StyledDivider = styled(Divider)`
    border-color: ${({ theme }) => theme.palette.blue.dark};
`;

const StyledBoxContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
`;

export const ModifySlotForm = memo(function RequestPopinForm() {
    const { initialValues, values, handleChange, setValues, setFieldValue } =
        useFormikContext<RequestPopinFormValues>();

    const isSlotUpdate = !!initialValues.airline;

    const handleChangePattern = useCallback(
        (e: ChangeEvent<HTMLInputElement>, day) => {
            const checked = e.target.checked;
            setValues(o => ({
                ...o,
                pattern: checked
                    ? (o.pattern || EMPTY_ARRAY).concat(day)
                    : o.pattern
                    ? o.pattern.filter(p => p !== day)
                    : EMPTY_ARRAY,
            }));
        },
        [setValues],
    );

    const trafficTypes = useCurrentUserClientTrafficTypes();

    const acTypeField = (
        <FormikIcaoIataTextField
            label={`AC Type`}
            id="acType"
            aria-label={`AC Type`}
            name="acType"
            required={!values.punctual}
            iataIcao={values.icaoIata}
        />
    );
    const airlineField = (
        <FormikIcaoIataTextField
            label={<FormattedMessage id={'requestForm.airline'} />}
            id="airline"
            aria-label={`Airline`}
            name="airline"
            required={!values.punctual}
            iataIcao={values.icaoIata}
            disabled={!values.punctual && isSlotUpdate}
        />
    );
    const paxCapacityField = (
        <FormikTextField
            label={<FormattedMessage id={'requestForm.paxCapacity'} />}
            id="paxCapacity"
            aria-label="PAX capacity"
            name="paxCapacity"
            type="number"
        />
    );

    return (
        <Form>
            {isSlotUpdate ? (
                !values.punctual ? (
                    <StyledLine justifyContent="space-between" alignItems="center">
                        <StyledTitle>
                            <FormattedMessage id={'requestForm.edit.flightSelection'} />
                        </StyledTitle>
                    </StyledLine>
                ) : null
            ) : (
                <StyledLine justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <StyledLabel>
                            <RoundSwitch
                                id="punctual"
                                name="punctual"
                                checked={!values.punctual}
                                onChange={e => setFieldValue('punctual', !e.target.checked)}
                            />
                            <FormattedMessage
                                id={values.punctual ? 'requestForm.isNotRecurrent' : 'requestForm.isRecurrent'}
                            />
                        </StyledLabel>
                    </Box>
                    <ToggleButtonGroup id="icaoIata" exclusive onChange={handleChange} value={values.icaoIata}>
                        <ToggleButton name="icaoIata" value="icao">
                            ICAO
                        </ToggleButton>
                        <ToggleButton name="icaoIata" value="iata">
                            IATA
                        </ToggleButton>
                    </ToggleButtonGroup>
                </StyledLine>
            )}
            {!values.punctual && (
                <>
                    <StyledLine justifyContent="center" alignItems="center">
                        <Box maxWidth={250}>
                            <FormikDatePicker
                                label={<FormattedMessage id={'requestForm.from'} />}
                                name="begin"
                                minDate={initialValues.begin ? new Date(initialValues.begin) : currentDate}
                                maxDate={initialValues.end ? new Date(initialValues.end) : undefined}
                                required={true}
                            />
                        </Box>
                        <Box ml={10} maxWidth={250}>
                            <FormikDatePicker
                                label={<FormattedMessage id={'requestForm.to'} />}
                                name="end"
                                minDate={values.begin ? new Date(values.begin) : currentDate}
                                maxDate={initialValues.end ? new Date(initialValues.end) : undefined}
                                required={true}
                            />
                        </Box>
                    </StyledLine>
                    <StyledLine justifyContent="center" alignItems="center">
                        {Object.keys(WeekDay).map((day, i) => (
                            <Checkbox
                                key={day}
                                name="pattern"
                                checked={(values.pattern || EMPTY_ARRAY).includes(day)}
                                disabled={isSlotUpdate && !(initialValues.pattern || EMPTY_ARRAY).includes(day)}
                                onChange={e => handleChangePattern(e, day)}
                                label={<FormattedTime format={'iiii'}>{getDay(i + 1)}</FormattedTime>}
                                sx={{ textTransform: 'capitalize' }}
                            />
                        ))}
                    </StyledLine>
                    <Box display={'flex'} justifyContent={'center'}>
                        <FormikErrorMessage name={'pattern'} />
                    </Box>
                </>
            )}
            {!values.punctual && <StyledDivider orientation="horizontal" sx={{ margin: '40px 0' }} />}
            {isSlotUpdate && (
                <StyledLine justifyContent="space-between" alignItems="center">
                    <StyledTitle>
                        <FormattedMessage id={'requestForm.edit.updateFields'} />
                    </StyledTitle>
                    <ToggleButtonGroup id="icaoIata" exclusive onChange={handleChange} value={values.icaoIata}>
                        <ToggleButton name="icaoIata" value="icao">
                            ICAO
                        </ToggleButton>
                        <ToggleButton name="icaoIata" value="iata">
                            IATA
                        </ToggleButton>
                    </ToggleButtonGroup>
                </StyledLine>
            )}
            {!values.punctual ? (
                <StyledLine justifyContent="center" alignItems="center">
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            {airlineField}
                        </Grid>
                        <Grid item xs={4}>
                            {acTypeField}
                        </Grid>
                        <Grid item xs={4}>
                            {paxCapacityField}
                        </Grid>
                    </Grid>
                </StyledLine>
            ) : (
                <StyledLine justifyContent="center" alignItems="center">
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            {airlineField}
                        </Grid>
                        <Grid item xs={3}>
                            <FormikTextField
                                label="REG"
                                id="registrationNumber"
                                aria-label="REG"
                                name="registrationNumber"
                                required={true}
                                disabled={isSlotUpdate}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            {acTypeField}
                        </Grid>
                        <Grid item xs={3}>
                            {paxCapacityField}
                        </Grid>
                    </Grid>
                </StyledLine>
            )}
            <StyledLine justifyContent="space-between" alignItems="flex-start">
                <StyledBoxContainer flex={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center" justifyContent="flex-start">
                                <IconLanding />
                                <StyledLabel sx={{ marginLeft: '22px' }}>
                                    <FormattedMessage id="requestForm.arrival" />
                                </StyledLabel>
                                <RoundSwitch
                                    id="arrivalFlight"
                                    name="arrivalFlight"
                                    checked={values.arrivalFlight}
                                    onChange={handleChange}
                                    sx={{ marginLeft: '70px' }}
                                    disabled={isSlotUpdate}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <FormikIcaoIataTextField
                                disabled={!values.arrivalFlight}
                                label={<FormattedMessage id={'requestForm.fromAirport'} />}
                                id="adep"
                                aria-label={`From`}
                                name="adep"
                                iataIcao={values.icaoIata}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormikTextField
                                disabled={!values.arrivalFlight || (!values.punctual && isSlotUpdate)}
                                label={<FormattedMessage id={'requestForm.flNumber'} />}
                                id="arrivalFlightNumber"
                                aria-label="FL Number"
                                name="arrivalFlightNumber"
                                required={!values.punctual}
                            />
                        </Grid>
                        {!values.punctual ? (
                            <>
                                <Grid item xs={6}>
                                    <FormikTimeField
                                        disabled={!values.arrivalFlight}
                                        name={'sibt'}
                                        date={values.begin!}
                                        required={true}
                                        label={'STA'}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Select
                                        disabled={!values.arrivalFlight}
                                        name="arrivalType"
                                        label={<FormattedMessage id={'requestForm.flightType'} />}
                                        value={values.arrivalType || ''}
                                        displayEmpty={true}
                                        onChange={handleChange}
                                        required={true}
                                    >
                                        <MenuItem value="">
                                            <em>-</em>
                                        </MenuItem>
                                        {(trafficTypes ? Array.from(trafficTypes) : EMPTY_ARRAY).map(tt => (
                                            <MenuItem key={tt.code} value={tt.code || ''}>
                                                {`${tt.code} - ${tt.descriptionEng}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={6}>
                                    <FormikTextField
                                        disabled={!values.arrivalFlight || isSlotUpdate}
                                        label={'Callsign'}
                                        id="arrivalCallsign"
                                        aria-label="Callsign"
                                        name="arrivalCallsign"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormikTextField
                                        disabled={!values.arrivalFlight}
                                        label={'PAX'}
                                        id="arrivalPax"
                                        aria-label="PAX"
                                        name="arrivalPax"
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormikDatePicker
                                        name="begin"
                                        disabled={!values.arrivalFlight || isSlotUpdate}
                                        minDate={currentDate}
                                        label={<FormattedMessage id={'requestForm.fromDate'} />}
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormikTimeField
                                        disabled={!values.arrivalFlight}
                                        name={'sibt'}
                                        date={values.begin!}
                                        required={true}
                                        label={'STA'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Select
                                        disabled={!values.arrivalFlight}
                                        name="arrivalType"
                                        label={<FormattedMessage id={'requestForm.flightType'} />}
                                        value={values.arrivalType || ''}
                                        displayEmpty={true}
                                        onChange={handleChange}
                                        required={true}
                                    >
                                        <MenuItem value="">
                                            <em>-</em>
                                        </MenuItem>
                                        {(trafficTypes ? Array.from(trafficTypes) : EMPTY_ARRAY).map(tt => (
                                            <MenuItem key={tt.code} value={tt.code || ''}>
                                                {`${tt.code} - ${tt.descriptionEng}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </StyledBoxContainer>

                <StyledDivider orientation="vertical" flexItem sx={{ margin: '0 40px' }} />

                <StyledBoxContainer flex={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center" justifyContent="flex-start">
                                <IconTakeOff />
                                <StyledLabel sx={{ marginLeft: '22px' }}>
                                    <FormattedMessage id="requestForm.departure" />
                                </StyledLabel>
                                <RoundSwitch
                                    id="departureFlight"
                                    name="departureFlight"
                                    checked={values.departureFlight}
                                    onChange={handleChange}
                                    sx={{ marginLeft: '70px' }}
                                    disabled={isSlotUpdate}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <FormikIcaoIataTextField
                                disabled={!values.departureFlight}
                                label={<FormattedMessage id={'requestForm.toAirport'} />}
                                id="ades"
                                aria-label={`Dest`}
                                name="ades"
                                iataIcao={values.icaoIata}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormikTextField
                                disabled={!values.departureFlight || (!values.punctual && isSlotUpdate)}
                                label={<FormattedMessage id={'requestForm.flNumber'} />}
                                id="departureFlightNumber"
                                aria-label="FL Number"
                                name="departureFlightNumber"
                                required={!values.punctual}
                            />
                        </Grid>
                        {!values.punctual ? (
                            <>
                                <Grid item xs={6}>
                                    <FormikTimeField
                                        disabled={!values.departureFlight}
                                        name={'sobt'}
                                        date={values.begin!}
                                        required={true}
                                        label={'STD'}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Select
                                        disabled={!values.departureFlight}
                                        name="departureType"
                                        label={<FormattedMessage id={'requestForm.flightType'} />}
                                        value={values.departureType || ''}
                                        displayEmpty={true}
                                        onChange={handleChange}
                                        required={true}
                                    >
                                        <MenuItem value="">
                                            <em>-</em>
                                        </MenuItem>
                                        {(trafficTypes ? Array.from(trafficTypes) : EMPTY_ARRAY).map(tt => (
                                            <MenuItem key={tt.code} value={tt.code || ''}>
                                                {`${tt.code} - ${tt.descriptionEng}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={6}>
                                    <FormikTextField
                                        disabled={!values.departureFlight || isSlotUpdate}
                                        label={'Callsign'}
                                        id="departureCallsign"
                                        aria-label="Callsign"
                                        name="departureCallsign"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormikTextField
                                        disabled={!values.departureFlight}
                                        label={'PAX'}
                                        id="departurePax"
                                        aria-label="PAX"
                                        name="departurePax"
                                        type="number"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormikDatePicker
                                        disabled={!values.departureFlight || isSlotUpdate}
                                        minDate={currentDate}
                                        label={<FormattedMessage id={'requestForm.toDate'} />}
                                        name="end"
                                        required={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormikTimeField
                                        disabled={!values.departureFlight}
                                        name={'sobt'}
                                        date={values.end!}
                                        required={true}
                                        label={'STD'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Select
                                        disabled={!values.departureFlight}
                                        name="departureType"
                                        label={<FormattedMessage id={'requestForm.flightType'} />}
                                        value={values.departureType || ''}
                                        displayEmpty={true}
                                        onChange={handleChange}
                                        required={true}
                                    >
                                        <MenuItem value="">
                                            <em>-</em>
                                        </MenuItem>
                                        {(trafficTypes ? Array.from(trafficTypes) : EMPTY_ARRAY).map(tt => (
                                            <MenuItem key={tt.code} value={tt.code || ''}>
                                                {`${tt.code} - ${tt.descriptionEng}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </StyledBoxContainer>
            </StyledLine>
        </Form>
    );
});
