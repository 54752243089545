import { GanttStripDto, ModificationDemandDto, PunctualUpdateDto, SlotVersionDto } from '@types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    tooltipClasses,
    TooltipProps,
} from '@mui/material';
import { STRIP_HEIGHT } from '@components/Strip/strip.constant';
import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { theme } from '../../theme';
import { makeStyles } from '@mui/styles';

const additionalInfoFields: Array<keyof PunctualUpdateDto> = [
    'arrivalFlightNumberFull',
    'departureFlightNumberFull',
    'arrivalPax',
    'departurePax',
    'arrivalNationality',
    'departureNationality',
];

interface StripAdditionalInfoProps {
    slot?: GanttStripDto | ModificationDemandDto | SlotVersionDto | null;
    slotUpdatePunctual?: PunctualUpdateDto | null;
}

export function StripAdditionalInfo({ slot, slotUpdatePunctual }: StripAdditionalInfoProps) {
    if (!slot?.punctual) {
        return null;
    }

    const updatedFields = additionalInfoFields.filter(field => {
        return _.has(slotUpdatePunctual, field);
    });
    const hasAdditionalInfoChanged = updatedFields.length > 0;

    return (
        <StyledContainer>
            <StyledTooltip
                title={
                    hasAdditionalInfoChanged ? (
                        <StripAdditionalInfoUpdateTooltipContent slot={slot} slotUpdatePunctual={slotUpdatePunctual} />
                    ) : (
                        <StripAdditionalInfoTooltipContent slot={slot} />
                    )
                }
            >
                <InfoOutlinedIcon
                    style={{
                        fontSize: '45px',
                        color: hasAdditionalInfoChanged ? theme.palette.impactStatus['UPDATE'].main : 'white',
                    }}
                />
            </StyledTooltip>
        </StyledContainer>
    );
}

interface StripAdditionalInfoTooltipContentProps {
    slot?: GanttStripDto | ModificationDemandDto | SlotVersionDto | null;
}

function StripAdditionalInfoTooltipContent({ slot }: StripAdditionalInfoTooltipContentProps) {
    const classes = useStyles();

    return (
        <TableContainer>
            <Table sx={{ minWidth: 300 }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center">
                            <FormattedMessage id={'strip.additionalInfo.arrival'} />
                        </TableCell>
                        <TableCell align="center">
                            <FormattedMessage id={'strip.additionalInfo.departure'} />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            <FormattedMessage id={'strip.additionalInfo.flNumber'} />
                        </TableCell>
                        <TableCell align="center">{slot?.arrivalFlightNumberFull || '-'}</TableCell>
                        <TableCell align="center">{slot?.departureFlightNumberFull || '-'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            PAX
                        </TableCell>
                        <TableCell align="center">{slot?.arrivalPax || '-'}</TableCell>
                        <TableCell align="center">{slot?.departurePax || '-'}</TableCell>
                    </TableRow>
                    <TableRow className={classes.lastTableRow}>
                        <TableCell component="th" scope="row">
                            <FormattedMessage id={'strip.additionalInfo.sectorCode'} />
                        </TableCell>
                        <TableCell align="center">{slot?.arrivalNationality || '-'}</TableCell>
                        <TableCell align="center">{slot?.departureNationality || '-'}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function StripAdditionalInfoUpdateTooltipContent({
    slot,
    slotUpdatePunctual,
}: {
    slot?: GanttStripDto | ModificationDemandDto | SlotVersionDto | null;
    slotUpdatePunctual?: PunctualUpdateDto | null;
}) {
    const classes = useStyles();
    const getValueCellColorClass = (field: keyof PunctualUpdateDto) => {
        if (!slotUpdatePunctual) {
            return '';
        }
        if (slotUpdatePunctual[field] !== slot?.[field]) {
            return classes.updatedValue;
        }
        return '';
    };

    return (
        <TableContainer>
            <Table sx={{ minWidth: 300 }} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.noBorderBottom} />
                        <TableCell align="center" colSpan={2} className={classes.borderLeftRight}>
                            <FormattedMessage id={'strip.additionalInfo.initial'} />
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                            <FormattedMessage id={'strip.additionalInfo.updated'} />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center" className={classes.borderLeft}>
                            <FormattedMessage id={'strip.additionalInfo.arrival'} />
                        </TableCell>
                        <TableCell align="center" className={classes.borderRight}>
                            <FormattedMessage id={'strip.additionalInfo.departure'} />
                        </TableCell>
                        <TableCell align="center">
                            <FormattedMessage id={'strip.additionalInfo.arrival'} />
                        </TableCell>
                        <TableCell align="center">
                            <FormattedMessage id={'strip.additionalInfo.departure'} />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            <FormattedMessage id={'strip.additionalInfo.flNumber'} />
                        </TableCell>
                        <TableCell align="center" className={classes.borderLeft}>
                            {slot?.arrivalFlightNumberFull || '-'}
                        </TableCell>
                        <TableCell align="center" className={classes.borderRight}>
                            {slot?.departureFlightNumberFull || '-'}
                        </TableCell>
                        <TableCell align="center" className={getValueCellColorClass('arrivalFlightNumberFull')}>
                            {slotUpdatePunctual?.arrivalFlightNumberFull || '-'}
                        </TableCell>
                        <TableCell align="center" className={getValueCellColorClass('departureFlightNumberFull')}>
                            {slotUpdatePunctual?.departureFlightNumberFull || '-'}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            PAX
                        </TableCell>
                        <TableCell align="center" className={classes.borderLeft}>
                            {slot?.arrivalPax || '-'}
                        </TableCell>
                        <TableCell align="center" className={classes.borderRight}>
                            {slot?.departurePax || '-'}
                        </TableCell>
                        <TableCell align="center" className={getValueCellColorClass('arrivalPax')}>
                            {slotUpdatePunctual?.arrivalPax || '-'}
                        </TableCell>
                        <TableCell align="center" className={getValueCellColorClass('departurePax')}>
                            {slotUpdatePunctual?.departurePax || '-'}
                        </TableCell>
                    </TableRow>
                    <TableRow className={classes.lastTableRow}>
                        <TableCell component="th" scope="row">
                            <FormattedMessage id={'strip.additionalInfo.sectorCode'} />
                        </TableCell>
                        <TableCell align="center" className={classes.borderLeft}>
                            {slot?.arrivalNationality || '-'}
                        </TableCell>
                        <TableCell align="center" className={classes.borderRight}>
                            {slot?.departureNationality || '-'}
                        </TableCell>
                        <TableCell align="center" className={getValueCellColorClass('arrivalNationality')}>
                            {slotUpdatePunctual?.arrivalNationality || '-'}
                        </TableCell>
                        <TableCell align="center" className={getValueCellColorClass('departureNationality')}>
                            {slotUpdatePunctual?.departureNationality || '-'}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

const useStyles = makeStyles({
    noBorderBottom: {
        borderLeft: 'none',
    },
    borderLeft: {
        borderLeft: '1px solid black',
    },
    borderRight: {
        borderRight: '1px solid black',
    },
    borderLeftRight: {
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
    },
    lastTableRow: {
        '&:last-child td, &:last-child th': {
            borderBottom: 'none',
        },
    },
    updatedValue: {
        color: theme.palette.impactStatus['UPDATE'].main,
    },
});

const StyledContainer = styled('div')(() => ({
    height: STRIP_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 16,
        maxWidth: 'unset',
    },
}));
