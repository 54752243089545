import { SlotVersionDto } from '@models';
import { Box, styled } from '@mui/material';
import React, { memo } from 'react';
import { STRIP_HEIGHT } from './strip.constant';
import { StripAircraftInfos } from './StripAircraftInfos';
import { StripDatesAndPatternOrReg } from './StripDatesAndPatternOrReg';
import { StripTrafficTypesInfos } from './StripTrafficTypesInfos';
import { StripTurnaroundInfos } from './StripTurnaroundInfos';
import { StripFlightNumbers } from '@components/Strip/StripFlightNumbers';
import { StripAdditionalInfo } from '@components/Strip/StripAdditionalInfo';

const StyledStrip = styled(Box)(({ theme }) => ({
    '@keyframes highlight': {
        from: { backgroundColor: theme.palette.warning.light },
        to: { backgroundColor: theme.palette.primary.light },
    },
    animation: 'none',
    backgroundColor: theme.palette.primary.light,
    height: STRIP_HEIGHT,
    borderRadius: `${theme.shape.borderRadiusLarge}px`,
    filter: 'drop-shadow(0 3px 6px rgba(0,0,0,0.3))',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    fontSize: 16,
}));

const StripAndTitleBox = styled(Box)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-bottom: 0;
    opacity: 1;
`;

export const StripHistoryInstance = memo(function StripHistoryInstance({
    slotStripDto,
}: {
    slotStripDto: SlotVersionDto;
}) {
    if (!slotStripDto.id) {
        return null;
    }

    return (
        <Box height={STRIP_HEIGHT} display="flex" position="relative" flexShrink={0}>
            <StripAndTitleBox>
                <Box display={'flex'} alignItems={'center'} flexShrink={0}>
                    <StyledStrip>
                        <StripFlightNumbers
                            arrival={
                                slotStripDto.punctual
                                    ? slotStripDto.arrivalCallsign
                                    : slotStripDto.arrivalFlightNumberFull
                            }
                            departure={
                                slotStripDto.punctual
                                    ? slotStripDto.departureCallsign
                                    : slotStripDto.departureFlightNumberFull
                            }
                        />
                        <StripDatesAndPatternOrReg slot={slotStripDto} />
                        <StripAircraftInfos slot={slotStripDto} />
                        <StripTurnaroundInfos slot={slotStripDto} />
                        <StripTrafficTypesInfos slot={slotStripDto} />
                    </StyledStrip>
                </Box>
            </StripAndTitleBox>
            <StripAdditionalInfo slot={slotStripDto} />
        </Box>
    );
});
