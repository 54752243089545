import React, { useCallback, useMemo } from 'react';
import { Form, Formik } from 'formik';
import { stopPropagation } from '../../utils/dom.utils';
import { FormattedMessage } from 'react-intl';
import { Box, MenuItem, styled } from '@mui/material';
import { FormikCheckbox } from '@components/form/Checkbox/FormikCheckbox';
import { FormikSelect } from '@components/form/Select/FormikSelect';
import { RoundButton } from '@components/Button/RoundButton/RoundButton';
import { SeasonFilter, useUiState } from '../../contexts/UiContext';
import { IcaoIataDto } from '@types';

interface FilterBySourceFormValues {
    selectedSeason: SeasonFilter;
    filterByPartner: boolean;
    selectedPartner: string;
    filterByAirline: boolean;
    selectedAirline: string;
}
interface FilterBySourceFormProps {
    onClose?: () => void;
    currentSeasonLabel?: string;
    nextSeasonLabel?: string;
    allowComFilter?: boolean;
    airlines?: IcaoIataDto[];
    allowOtherFilter?: boolean;
    partners?: string[];
}
export const FilterBySourceForm = ({
    onClose,
    currentSeasonLabel,
    nextSeasonLabel,
    allowComFilter,
    airlines,
    allowOtherFilter,
    partners,
}: FilterBySourceFormProps) => {
    const [seasonFilter, setSeasonFilter] = useUiState('seasonFilter');
    const [partnerFilter, setPartnerFilter] = useUiState('partnerFilter');
    const [filterByPartner, setFilterByPartner] = useUiState('filterByPartner');
    const [airlineFilter, setAirlineFilter] = useUiState('airlineFilter');
    const [filterByAirline, setFilterByAirline] = useUiState('filterByAirline');

    const initialValues: FilterBySourceFormValues = useMemo(
        () => ({
            selectedSeason: seasonFilter,
            filterByPartner: !!(allowOtherFilter && filterByPartner),
            selectedPartner: partnerFilter || '',
            filterByAirline: !!(allowComFilter && filterByAirline),
            selectedAirline: airlineFilter || '',
        }),
        [
            seasonFilter,
            allowOtherFilter,
            filterByPartner,
            partnerFilter,
            allowComFilter,
            filterByAirline,
            airlineFilter,
        ],
    );

    const handleSubmit = useCallback(
        values => {
            setSeasonFilter(values.selectedSeason);
            setFilterByPartner(values.filterByPartner);
            setPartnerFilter(values.filterByPartner ? values.selectedPartner : undefined);
            setFilterByAirline(values.filterByAirline);
            setAirlineFilter(values.filterByAirline ? values.selectedAirline : undefined);
            onClose?.();
        },
        [setSeasonFilter, setFilterByPartner, setPartnerFilter, setFilterByAirline, setAirlineFilter, onClose],
    );

    return (
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {props => (
                <Form>
                    <StyledContainer onClick={stopPropagation}>
                        <FormikSelect
                            name="selectedSeason"
                            label={<FormattedMessage id={'requests.actions.filter.season.label'} />}
                            fullWidth
                        >
                            <MenuItem value={'ALL'}>
                                <FormattedMessage id={'requests.actions.filter.season.ALL'} />
                            </MenuItem>
                            <MenuItem value={'CURRENT'}>
                                <FormattedMessage
                                    id={'requests.actions.filter.season.CURRENT'}
                                    values={{
                                        season: currentSeasonLabel,
                                    }}
                                />
                            </MenuItem>
                            <MenuItem value={'NEXT'}>
                                <FormattedMessage
                                    id={'requests.actions.filter.season.NEXT'}
                                    values={{
                                        season: nextSeasonLabel,
                                    }}
                                />
                            </MenuItem>
                        </FormikSelect>

                        <Box display={'flex'} justifyContent={'center'} gap={'10px'}>
                            <Box width={'50%'}>
                                <FormikCheckbox name={'filterByAirline'} label="Com" disabled={!allowComFilter} />
                                <FormikSelect
                                    name="selectedAirline"
                                    fullWidth
                                    disabled={!props.values.filterByAirline}
                                    displayEmpty={true}
                                >
                                    <MenuItem value="">
                                        <FormattedMessage id={'requests.actions.filter.all'} />
                                    </MenuItem>
                                    {airlines?.map(airline => {
                                        if (airline.iata) {
                                            return (
                                                <MenuItem key={airline.iata} value={airline.iata}>
                                                    {airline.iata}
                                                </MenuItem>
                                            );
                                        } else return null;
                                    })}
                                </FormikSelect>
                            </Box>

                            <Box width={'50%'}>
                                <FormikCheckbox name={'filterByPartner'} label="Other" disabled={!allowOtherFilter} />
                                <FormikSelect
                                    name="selectedPartner"
                                    fullWidth
                                    disabled={!props.values.filterByPartner}
                                    displayEmpty={true}
                                >
                                    <MenuItem value="">
                                        <FormattedMessage id={'requests.actions.filter.all'} />
                                    </MenuItem>
                                    {partners?.map(partner => (
                                        <MenuItem key={partner} value={partner}>
                                            {partner}
                                        </MenuItem>
                                    ))}
                                </FormikSelect>
                            </Box>
                        </Box>

                        <StyledButtonContainer>
                            <Box marginRight="5px">
                                <RoundButton size="medium" variant="outlined" onClick={onClose}>
                                    <FormattedMessage id="viewMode.popin.cancel" />
                                </RoundButton>
                            </Box>
                            <Box marginLeft="5px">
                                <RoundButton size="medium" variant="contained" type={'submit'}>
                                    <FormattedMessage id="viewMode.popin.applyFilters" />
                                </RoundButton>
                            </Box>
                        </StyledButtonContainer>
                    </StyledContainer>
                </Form>
            )}
        </Formik>
    );
};

const StyledContainer = styled(Box)`
    padding: 40px;
    min-width: 460px;
`;

const StyledButtonContainer = styled(Box)`
    margin-top: 0;
    display: flex;
    justify-content: space-between;
`;
