import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { Locales } from '@innovatm/js-iam';

export const localeToStripRecurringDateFormat: Record<Locales, string> = {
    [Locales.en]: 'LLL dd',
    [Locales.fr]: 'dd LLL',
};
export const localeToStripPunctualDateFormat: Record<Locales, string> = {
    [Locales.en]: 'iii LLL dd',
    [Locales.fr]: 'iii dd LLL',
};

export const STRIP_HOURS = 'HH:mm';

export const STRIP_HEIGHT = 60;
export const STRIP_TITLE_HEIGHT = 30;
export const STRIP_WITH_UPDATE_HEIGHT = STRIP_HEIGHT * 2 + 5;

export const StripDateStyle: SxProps<Theme> = {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 16,
    textTransform: 'uppercase',
};
