import React, { useCallback, useEffect } from 'react';
import { FilterRequestsButton } from '@components/Requests/RequestAdd/FilterRequestsButton';
import { FormattedMessage } from 'react-intl';
import { useUiState } from '../../../contexts/UiContext';
import { PopoverMenu } from '@components/navigation/PopoverMenu/PopoverMenu';
import { CircularProgress } from '@mui/material';
import { useQueryGetGetSlotFilters } from '@models';
import { FilterBySourceForm } from '@components/Requests/FilterBySourceForm';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

export const FilterRequestsBySource = () => {
    const [seasonFilter, setSeasonFilter] = useUiState('seasonFilter');
    const [filterByAirline] = useUiState('filterByAirline');
    const [airlineFilter] = useUiState('airlineFilter');
    const [filterByPartner] = useUiState('filterByPartner');
    const [partnerFilter] = useUiState('partnerFilter');

    const {
        data: filters,
        isLoading: isLoadingFilters,
        error: errorFilters,
    } = useQueryGetGetSlotFilters({
        refetchOnMount: true,
        refetchInterval: 2 * 60 * 60 * 1000, // 2 hours in milliseconds
    });

    const currentSeasonLabel = filters?.currentSeason?.label;
    const nextSeasonLabel = filters?.nextSeason?.label;

    const renderButton = useCallback(
        ({ onClick, opened }) => (
            <FilterRequestsButton onClick={onClick} opened={opened}>
                {isLoadingFilters ? (
                    <CircularProgress size={25} color="info" />
                ) : (
                    <>
                        <FormattedMessage
                            id={`requests.actions.filter.season.${seasonFilter}`}
                            values={{
                                season:
                                    seasonFilter === 'CURRENT'
                                        ? currentSeasonLabel
                                        : seasonFilter === 'NEXT'
                                        ? nextSeasonLabel
                                        : undefined,
                            }}
                        />
                        {(filters?.com && !filterByAirline) ||
                        (filters?.other && !filterByPartner) ||
                        (filterByAirline && filterByPartner && (airlineFilter || partnerFilter)) ? (
                            <FilterAltIcon sx={{ ml: 1, mr: -1 }} fontSize="small" />
                        ) : (
                            <FilterAltOffIcon sx={{ ml: 1, mr: -1 }} fontSize="small" />
                        )}
                    </>
                )}
            </FilterRequestsButton>
        ),
        [
            airlineFilter,
            currentSeasonLabel,
            filterByAirline,
            filterByPartner,
            filters?.com,
            filters?.other,
            isLoadingFilters,
            nextSeasonLabel,
            partnerFilter,
            seasonFilter,
        ],
    );

    // If filters query fails, set seasonFilter to ALL
    // This is because by default, the seasonFilter is set to 'CURRENT' in the UIContext
    useEffect(() => {
        if (errorFilters) {
            setSeasonFilter('ALL');
        }
    }, [errorFilters, setSeasonFilter]);

    return (
        <PopoverMenu renderButton={renderButton} autoClose={true}>
            {({ close }) => (
                <FilterBySourceForm
                    onClose={close}
                    currentSeasonLabel={currentSeasonLabel}
                    nextSeasonLabel={nextSeasonLabel}
                    allowComFilter={filters?.com}
                    airlines={filters?.airlines}
                    allowOtherFilter={filters?.other}
                    partners={filters?.partners}
                />
            )}
        </PopoverMenu>
    );
};
