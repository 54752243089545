/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */

import * as React from 'react';
import { useCallback } from 'react';
import { createContext, useContext, useContextSelector } from 'use-context-selector';
import { useOptimizedState } from '../hooks/useOptimizedState';
import { DemandStatus, ResourceType } from '@types';

export enum RequestsOrderEnum {
    'ASC' = 'ASC',
    'DESC' = 'DESC',
    'FIRST_FLIGHT' = 'FIRST_FLIGHT',
    'LAST_FLIGHT' = 'LAST_FLIGHT',
}

export enum CodeMode {
    ICAO = 'ICAO',
    IATA = 'IATA',
}

export type SeasonFilter = 'CURRENT' | 'NEXT' | 'ALL';
export interface Ui {
    search: string;
    // Get the selectedDate with useUiValue('selectedDate') instead of useParams()?.date
    // Don't set selectedDate directly with useSetUi('selectedDate'), use useGoToSchedule() instead
    selectedDate: Date;
    sortByResource: ResourceType | undefined;
    selectedMode: 'projected' | 'current';
    selectedCodeMode: CodeMode;
    selectedFlightId: string | null;
    selectedSlotId: string | null;
    highlightedSlotId: string | null;
    showCancelledRequests: boolean;
    showTurnaroundResources: Partial<Record<ResourceType, boolean>>;
    requestFilter: DemandStatus | undefined;
    seasonFilter: SeasonFilter;
    filterByPartner: boolean;
    partnerFilter: string | undefined;
    filterByAirline: boolean;
    airlineFilter: string | undefined;
    requestOrder: RequestsOrderEnum;
}

export const initialUi: Ui = {
    search: '',
    selectedDate: new Date(),
    sortByResource: undefined,
    selectedMode: 'projected',
    selectedCodeMode: CodeMode.IATA,
    selectedFlightId: null,
    selectedSlotId: null,
    highlightedSlotId: null,
    showCancelledRequests: true,
    showTurnaroundResources: {
        [ResourceType.TERMINAL]: true,
        [ResourceType.PARKING]: true,
    },
    requestFilter: undefined,
    seasonFilter: 'CURRENT',
    filterByPartner: true,
    partnerFilter: undefined,
    filterByAirline: true,
    airlineFilter: undefined,
    requestOrder: RequestsOrderEnum.DESC,
};

export interface UiState {
    ui: Ui;
    setUi: React.Dispatch<React.SetStateAction<Ui>>;
}

const initialValue: UiState = {
    ui: initialUi,
    setUi: () => {
        /**/
    },
};

export const UiContext = createContext<UiState>(initialValue);

export const useUi = () => useContext(UiContext);

export const useUiSet = () => useContextSelector(UiContext, state => state.setUi);

export const useUiValue = <Key extends keyof Ui>(key: Key): Ui[Key] =>
    useContextSelector(UiContext, state => state.ui[key]);

export const useSetUi = <Key extends keyof Ui>(key: Key) => {
    const set = useUiSet();

    return useCallback(
        (value: React.SetStateAction<Ui[Key]>) =>
            set(oldValue => {
                const newValue = typeof value === 'function' ? (value as any)(oldValue[key]) : value;

                return {
                    ...oldValue,
                    [key]: newValue,
                };
            }),
        [key, set],
    );
};

export const useSetUiValue = <Key extends keyof Ui>(key: Key, value: React.SetStateAction<Ui[Key]>) => {
    const set = useSetUi(key);

    return useCallback(() => set(value), [set, value]);
};

export const useUiState = <Key extends keyof Ui>(
    key: Key,
): [Ui[Key], React.Dispatch<React.SetStateAction<Ui[Key]>>] => [useUiValue(key), useSetUi(key)];

export const UiContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [ui, setUi] = useOptimizedState<Ui>(initialUi);

    return <UiContext.Provider value={{ ui, setUi }}>{children}</UiContext.Provider>;
};
