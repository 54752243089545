import React, { memo, useCallback } from 'react';
import { ListItemText, MenuItem, MenuProps, styled } from '@mui/material';
import { PopoverMenu } from '@components/navigation/PopoverMenu/PopoverMenu';
import { FormattedMessage } from 'react-intl';
import { SquareButton } from '@components/Button/SquareButton/SquareButton';
import { ReactComponent as AllocateIcon } from '../../assets/icons/icon-allocate.svg';
import { ResourcesPropagatePopin } from './Propagate/ResourcesPropagatePopin';
import { useBoolean } from '../../hooks/useBoolean';
import { ResourcesAllocatePopin } from '@components/Resources/Allocate/ResourcesAllocatePopin';
import { useCurrentUserClient, useCurrentUserProfilePermissions } from '../../contexts/AuthContext.selectors';
import { AccessPermissions } from '@types';

const StyledPropagateButton = styled(SquareButton)`
    margin: auto;
    text-transform: uppercase;
    padding: 19px 20px;
`;

const menuProps: Partial<MenuProps> = {
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'left',
    },
};

export const ResourcesMenu = memo(function ResourcesMenu({ disabled }: { disabled?: boolean }) {
    const permissions = useCurrentUserProfilePermissions();
    const hasSlotManagementPermission = permissions && permissions.includes(AccessPermissions.SLOT_MANAGEMENT);

    const { value: openedPropagate, setTrue: openPropagate, setFalse: closePropagate } = useBoolean();
    const { value: openedAllocate, setTrue: openAllocate, setFalse: closeAllocate } = useBoolean();

    const rms = useCurrentUserClient()?.rms;

    const renderButton = useCallback(
        ({ onClick }) => (
            <StyledPropagateButton
                color="secondary"
                size="small"
                variant="contained"
                onClick={onClick}
                startIcon={<AllocateIcon />}
                disabled={disabled || !hasSlotManagementPermission}
            >
                <FormattedMessage id="schedule.resources" />
            </StyledPropagateButton>
        ),
        [disabled, hasSlotManagementPermission],
    );

    return (
        <>
            <PopoverMenu autoClose renderButton={renderButton} menuProps={menuProps}>
                <MenuItem onClick={openPropagate}>
                    <ListItemText>
                        <FormattedMessage id="schedule.propagate.menuItem" />
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={openAllocate} disabled={!rms}>
                    <ListItemText>
                        <FormattedMessage id="schedule.allocate.menuItem" />
                    </ListItemText>
                </MenuItem>
            </PopoverMenu>
            {openedPropagate && <ResourcesPropagatePopin onClose={closePropagate} />}
            {openedAllocate && <ResourcesAllocatePopin onClose={closeAllocate} />}
        </>
    );
});
