import { IcaoIata } from '@components/IcaoIata';
import {
    AccessPermissions,
    KeyGetGetAllSlotRequests,
    KeyGetGetSlotFilters,
    SlotRequestDto,
    useMutationDeleteUndoRequest,
    useMutationPostCompleteRequest,
} from '@models';
import { Box, IconButton, styled, Tooltip } from '@mui/material';
import React, { memo, useCallback } from 'react';
import { RequestSlot } from '@components/Requests/RequestSlot';
import { useDateFnsFormat } from '../../hooks/useDateFnsFormat';
import CloseIcon from '@mui/icons-material/Close';
import { useConfirmDialog } from '@components/confirm/useConfirmDialog';
import { FormattedMessage } from 'react-intl';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { errorToastConfig, successToastConfig } from '../../config/constants';
import { SquareButton } from '@components/Button/SquareButton/SquareButton';
import { useSetUi } from '../../contexts/UiContext';
import { useIntlValue } from '@innovatm/js-iam';
import { localeToDateTimeFormat } from '@components/time/time.utils';
import { useCurrentUserProfilePermissions } from '../../contexts/AuthContext.selectors';

const RequestInformation = styled(Box)`
    margin-bottom: 10px;
    width: 100%;
    color: ${({ theme }) => theme.palette.primary.light};
    font-family: Roboto, serif;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    height: 18px;
    padding-left: 77px;
`;

const RequestSlotsContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: ${({ theme }) => theme.shape.borderRadiusLarge}px;
    background-color: ${({ theme }) => theme.palette.secondary.main};
    padding: 70px 50px 20px;
    position: relative;
`;

const StyledBoxIcon = styled(Box)`
    position: absolute;
    top: 7px;
    right: 5px;
`;

const StyledBoxRequestActions = styled(Box)`
    position: absolute;
    top: 10px;
    right: 50px;
`;

export const Request = memo(function Request({
    request,
    scrollToSlotId,
}: {
    request: SlotRequestDto;
    scrollToSlotId?: string | null;
}) {
    const permissions = useCurrentUserProfilePermissions();
    const hasSlotManagementPermission = permissions && permissions.includes(AccessPermissions.SLOT_MANAGEMENT);

    const locale = useIntlValue('locale');
    const format = useDateFnsFormat();
    const confirm = useConfirmDialog({ disableReject: true });
    const setHighlightedSlotId = useSetUi('highlightedSlotId');

    const queryClient = useQueryClient();
    const { mutateAsync: deleteRequest } = useMutationDeleteUndoRequest({
        onSuccess: () => {
            toast(<FormattedMessage id={'requests.toasts.deleteSuccess'} />, successToastConfig);
            queryClient.invalidateQueries(KeyGetGetAllSlotRequests);
            queryClient.invalidateQueries(KeyGetGetSlotFilters);
            setHighlightedSlotId(null);
        },
        onError: () => {
            toast(<FormattedMessage id={'requestForm.error.default'} />, errorToastConfig);
        },
    });

    const { mutateAsync: completeRequest } = useMutationPostCompleteRequest({
        onSuccess: () => {
            toast(<FormattedMessage id={'requests.toasts.completeSuccess'} />, successToastConfig);
            queryClient.invalidateQueries(KeyGetGetAllSlotRequests);
        },
        onError: () => {
            toast(<FormattedMessage id={'requestForm.error.default'} />, errorToastConfig);
        },
    });

    const onClick = useCallback(() => {
        confirm({
            content: <FormattedMessage id={'requests.actions.delete'} />,
            confirmationText: <FormattedMessage id={'confirm.default.confirm'} />,
            cancellationText: <FormattedMessage id={'confirm.default.cancel'} />,
        }).then(() => {
            deleteRequest({ id: request.id });
        });
    }, [confirm, deleteRequest, request.id]);

    const onCompleteRequest = useCallback(() => {
        confirm({
            content: <FormattedMessage id={'requests.actions.complete'} />,
            confirmationText: <FormattedMessage id={'confirm.default.confirm'} />,
            cancellationText: <FormattedMessage id={'confirm.default.cancel'} />,
        }).then(() => {
            completeRequest({ id: request.id });
        });
    }, [confirm, completeRequest, request.id]);

    return (
        <Box margin="20px auto">
            <RequestInformation>
                {request.season && `${request.season} - `}
                {request.type === 'PARTNER'
                    ? request.partner && `${request.partner} - `
                    : request.airline && (
                          <>
                              <IcaoIata {...request.airline} /> -{' '}
                          </>
                      )}

                {request.date && format(request.date, localeToDateTimeFormat[locale])}
                {(request.type === 'IMPORT' || request.type === 'PARTNER') && (
                    <span>
                        <FormattedMessage id={'requests.header.file'} /> "{request.fileName}"{' '}
                        <FormattedMessage id={'requests.header.by'} /> {request.userName}
                    </span>
                )}
                {request.type === 'SSIM' && !request.userName && (
                    <span>
                        <FormattedMessage id={'requests.header.ssim'} /> {request.userEmail}
                    </span>
                )}
                {request.type === 'SSIM' && request.userName && (
                    <span>
                        <FormattedMessage id={'requests.header.ssimManual'} /> {request.userName}
                    </span>
                )}
                {request.type === 'MANUAL' && (
                    <span>
                        <FormattedMessage id={'requests.header.manual'} /> {request.userName}
                    </span>
                )}
            </RequestInformation>

            <RequestSlotsContainer>
                {hasSlotManagementPermission && request.undoable ? (
                    <StyledBoxIcon>
                        <IconButton onClick={onClick}>
                            <CloseIcon sx={{ width: 25, height: 25 }} />
                        </IconButton>
                    </StyledBoxIcon>
                ) : (
                    <Tooltip title={<FormattedMessage id={'requests.tooltip.notUndoable'} />} placement={'top'}>
                        {/*Absolute positioning on Box required due to disabled element (and not on Button element).
                        See https://mui.com/components/tooltips/#disabled-elements */}
                        <StyledBoxIcon>
                            <IconButton disabled={true}>
                                <CloseIcon sx={{ width: 25, height: 25 }} />
                            </IconButton>
                        </StyledBoxIcon>
                    </Tooltip>
                )}
                {request.demands.some(demand => demand.nbPending > 0) && (
                    <StyledBoxRequestActions>
                        <SquareButton
                            onClick={onCompleteRequest}
                            size="small"
                            variant="contained"
                            color={'primary'}
                            disabled={!hasSlotManagementPermission}
                        >
                            <FormattedMessage id="request.actions.completeAll" />
                        </SquareButton>
                    </StyledBoxRequestActions>
                )}

                {request.demands?.map(requestSlot => (
                    <RequestSlot
                        key={requestSlot.id}
                        requestSlot={requestSlot}
                        scrollOnLoad={scrollToSlotId === requestSlot.id}
                    />
                ))}
            </RequestSlotsContainer>
        </Box>
    );
});
