import { GanttStripDto, ModificationDemandDto, SlotVersionDto } from '@models';
import { Box, Typography } from '@mui/material';
import { format as dateFnsFormat, setDay } from 'date-fns';
import React, { memo } from 'react';
import { localeToStripPunctualDateFormat, localeToStripRecurringDateFormat, StripDateStyle } from './strip.constant';
import { useDateFnsFormat } from '../../hooks/useDateFnsFormat';
import { StripElement } from '@components/Strip/StripElement';
import { ArrowRight } from '@components/icons/ArrowRight';
import AirlinesIcon from '@mui/icons-material/Airlines';
import { Locales, useIntlValue } from '@innovatm/js-iam';

const days = Array(7).fill(undefined);
const getDay = (dayNumber: number) => setDay(new Date(), dayNumber);

interface WeekDaysPatternProps {
    pattern?: string[] | null;
}

const WeekDaysPattern = memo(({ pattern }: WeekDaysPatternProps) => {
    const format = useDateFnsFormat();
    return (
        <>
            {days.map((a, i) => (
                <Typography
                    key={i}
                    fontSize="16px"
                    sx={{
                        fontFamily: 'Roboto',
                        fontWeight: 'bold',
                        textTransform: 'lowercase',
                        color: pattern?.includes(dateFnsFormat(getDay(i + 1), 'iiii').toUpperCase())
                            ? theme => theme.palette.blue.dark
                            : '#B6D2E5',
                    }}
                >
                    {format(getDay(i + 1), 'iii').replace('.', '')}
                </Typography>
            ))}
        </>
    );
});

const StripDatesAndPatternLabel = memo(
    ({ slot, locale }: { slot?: GanttStripDto | ModificationDemandDto | SlotVersionDto | null; locale: Locales }) => {
        const format = useDateFnsFormat();
        return (
            <>
                <Box minWidth="155px" display="flex">
                    <Typography sx={{ ...StripDateStyle, fontSize: 15 }} minWidth="155px">
                        {slot?.begin && format(slot.begin, localeToStripRecurringDateFormat[locale]).replace('.', '')}
                        <ArrowRight />
                        {slot?.end && format(slot.end, localeToStripRecurringDateFormat[locale]).replace('.', '')}
                    </Typography>
                </Box>
                <Box display="flex" justifyContent="space-evenly" width="100%">
                    <WeekDaysPattern pattern={slot?.pattern} />
                </Box>
            </>
        );
    },
);

export const StripDatesAndPatternOrReg = memo(function StripDatesAndPattern({
    slot,
    sectionMinWidth = '463px',
}: {
    slot?: GanttStripDto | ModificationDemandDto | SlotVersionDto | null;
    sectionMinWidth?: string;
}) {
    const format = useDateFnsFormat();
    const locale = useIntlValue('locale');

    return (
        <Box position="relative">
            <StripElement zIndex="10" minWidth={sectionMinWidth}>
                {slot?.punctual ? (
                    <>
                        <Box minWidth="155px" display="flex">
                            <Typography sx={{ ...StripDateStyle, fontSize: 15 }} minWidth="155px">
                                {slot.begin && format(slot.begin, localeToStripPunctualDateFormat[locale])}
                                {slot.begin && slot.end && <ArrowRight />}
                                {slot.end && format(slot.end, localeToStripPunctualDateFormat[locale])}
                            </Typography>
                        </Box>
                        <AirlinesIcon fontSize={'large'} />
                        <Typography sx={{ ...StripDateStyle, fontSize: 15 }}>{slot?.registrationNumber}</Typography>
                    </>
                ) : (
                    <StripDatesAndPatternLabel slot={slot} locale={locale} />
                )}
            </StripElement>
        </Box>
    );
});
