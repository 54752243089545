import React, { memo } from 'react';
import { ActiveVersionDto, ModificationDemandDto, WeekDay } from '@types';
import { setDay } from 'date-fns';
import { Box, ListItemText } from '@mui/material';
import { useDateFnsFormat } from '../../../hooks/useDateFnsFormat';
import { ArrowRight } from '@components/icons/ArrowRight';

const getDay = (dayNumber: number) => setDay(new Date(), dayNumber);

export const StripMenuListItemTextUpdatedVersionOccurrence = memo(function StripMenuItemUpdatedVersionOccurrence({
    slot,
    activeVersion,
}: {
    slot: ModificationDemandDto;
    activeVersion: ActiveVersionDto;
}) {
    const format = useDateFnsFormat();

    const flightNumbers = (
        <>
            <Box width={55} textAlign={'center'}>
                {activeVersion.arrivalFlightKey}
            </Box>
            <ArrowRight />
            <Box width={55} textAlign={'center'}>
                {activeVersion.departureFlightKey}
            </Box>
        </>
    );

    const dates = (
        <Box width={130}>
            {format(slot.begin!, 'dd LLL')} <ArrowRight /> {format(slot.end!, 'dd LLL')}
        </Box>
    );

    const pattern = slot.pattern
        ?.map(day => format(getDay(Object.keys(WeekDay).indexOf(day) + 1), 'iiii').substring(0, 3))
        .join(', ');

    return (
        <ListItemText>
            <Box display={'flex'}>
                {flightNumbers}
                &emsp;
                {dates}
                &emsp;
                {pattern || '-'}
            </Box>
        </ListItemText>
    );
});
