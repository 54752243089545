import React, { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { RequestPage } from './Request/RequestPage/RequestPage';
import { SchedulePage } from './Schedule/SchedulePage/SchedulePage';
import {
    ROUTE_FORGOTPASSWORD,
    ROUTE_HELP,
    ROUTE_HOME,
    ROUTE_LOGIN,
    ROUTE_REQUESTS,
    ROUTE_RESETPASSWORD,
    ROUTE_SCHEDULE,
    ROUTE_SETTINGS,
    ROUTE_UNLOCKACCOUNT,
} from '../routes';
import { RequireAuth } from '@components/routing/RequireAuth/RequireAuth';
import { SettingsPage } from 'views/Settings/SettingsPage/SettingsPage';
import { AuthContext, useAuth } from '../contexts/AuthContext.selectors';
import { Crisp } from '@innovatm/js-crisp';
import { useSyncUserLocale } from '@innovatm/js-iam';
import { HelpPage } from './Help/HelpPage';
import { LoginRoute } from './Login/LoginRoute';
import { ForgottenPasswordRoute } from './ForgottenPassword/ForgottenPasswordRoute';
import { ResetPasswordRoute } from './ResetPassword/ResetPasswordRoute';
import { UnlockAccountRoute } from './UnlockAccount/UnlockAccountRoute';
import { AccessPermissions } from '@types';

export const App = memo(function App() {
    const email = useAuth('currentUser')?.email;

    // Update IntlContext locale as soon as the language selected by the user changes (server side)
    useSyncUserLocale(AuthContext);

    return (
        <>
            <Crisp appId={process.env.REACT_APP_CRISP_APP_ID} email={email} />
            <Routes>
                <Route
                    path={ROUTE_HOME}
                    element={
                        <RequireAuth requiredPermission={AccessPermissions.GANTT_PAGE}>
                            <SchedulePage />
                        </RequireAuth>
                    }
                />
                <Route path={ROUTE_LOGIN} element={<LoginRoute />} />
                <Route path={ROUTE_FORGOTPASSWORD} element={<ForgottenPasswordRoute />} />
                <Route path={ROUTE_RESETPASSWORD} element={<ResetPasswordRoute />} />
                <Route path={ROUTE_UNLOCKACCOUNT} element={<UnlockAccountRoute />} />
                <Route
                    path={ROUTE_REQUESTS}
                    element={
                        <RequireAuth requiredPermission={AccessPermissions.REQUEST_PAGE}>
                            <RequestPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path={ROUTE_SCHEDULE}
                    element={
                        <RequireAuth requiredPermission={AccessPermissions.GANTT_PAGE}>
                            <SchedulePage />
                        </RequireAuth>
                    }
                />
                <Route
                    path={ROUTE_SETTINGS}
                    element={
                        <RequireAuth>
                            <SettingsPage />
                        </RequireAuth>
                    }
                />
                <Route
                    path={ROUTE_HELP}
                    element={
                        <RequireAuth>
                            <HelpPage />
                        </RequireAuth>
                    }
                />
            </Routes>
        </>
    );
});
