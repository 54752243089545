import { StripMenu } from '@components/Strip/StripMenu/StripMenu';
import { AccessPermissions, ActiveVersionDto, DemandStatus, ModificationDemandDto } from '@models';
import React, { memo } from 'react';
import { StripMenuItemConfirmAllOccurrences } from '@components/Strip/StripMenu/StripMenuItemConfirmAllOccurrences';
import { StripMenuItemCheckOnGanttView } from '@components/Strip/StripMenu/StripMenuItemCheckOnGanttView';
import { StripMenuListItemTextUpdatedVersionOccurrence } from '@components/Strip/StripMenu/StripMenuListItemTextUpdatedVersionOccurrence';
import { StripMenuItemSeeUpdateHistory } from '@components/Strip/StripMenu/StripMenuItemSeeUpdateHistory';
import { useBoolean } from '../../hooks/useBoolean';
import { SlotHistory } from '@components/SlotHistory/SlotHistory';
import { Divider } from '@mui/material';
import { useCurrentUserProfilePermissions } from '../../contexts/AuthContext.selectors';

export const StripMenuRequestPage = memo(
    ({
        slot,
        disabled,
        hasPending,
        status,
        updatedVersions,
    }: {
        slot: ModificationDemandDto;
        disabled?: boolean;
        hasPending?: boolean;
        status: DemandStatus;
        updatedVersions?: Array<ActiveVersionDto>;
    }) => {
        const permissions = useCurrentUserProfilePermissions();
        const canGoToSchedule = permissions && permissions.includes(AccessPermissions.GANTT_PAGE);
        const hasSlotManagementPermission = permissions && permissions.includes(AccessPermissions.SLOT_MANAGEMENT);

        const {
            value: isSlotHistoryPopinOpened,
            setFalse: closeSlotHistoryPopin,
            setTrue: openSlotHistoryPopin,
        } = useBoolean();

        if (!slot) {
            return null;
        }

        return (
            <>
                <StripMenu disabled={disabled}>
                    {canGoToSchedule &&
                        (updatedVersions?.length ? (
                            updatedVersions.map(updatedVersion => (
                                <StripMenuItemCheckOnGanttView key={updatedVersion.id} slot={updatedVersion}>
                                    {updatedVersions.length > 1 ? (
                                        <StripMenuListItemTextUpdatedVersionOccurrence
                                            slot={slot}
                                            activeVersion={updatedVersion}
                                        />
                                    ) : null}
                                </StripMenuItemCheckOnGanttView>
                            ))
                        ) : (
                            <StripMenuItemCheckOnGanttView slot={slot} />
                        ))}
                    <StripMenuItemSeeUpdateHistory onClick={openSlotHistoryPopin} />

                    {/*Menu component does not accept jsx fragments as children*/}
                    {status === DemandStatus.NEW && hasPending && hasSlotManagementPermission && <Divider />}
                    {status === DemandStatus.NEW && hasPending && hasSlotManagementPermission && (
                        <StripMenuItemConfirmAllOccurrences slot={slot} />
                    )}

                    {/*{status === DemandStatus.NEW && (*/}
                    {/*    <StripMenuItemModifyAllOccurrences slot={slot} onClick={openModifySlotPopin} />*/}
                    {/*    <StripMenuItemCancelAllOccurrences slot={slot} hasPending={hasPending} />*/}
                    {/*    <StripMenuItemSplitArrDep slot={slot} />*/}
                    {/*)}  */}
                </StripMenu>

                {/*{isModifySlotPopinOpened && <ModifySlot slot={slot} onClose={onClose} />}*/}
                {isSlotHistoryPopinOpened && <SlotHistory slotId={slot.id!} onClose={closeSlotHistoryPopin} />}
            </>
        );
    },
);
