import { Box, styled } from '@mui/material';
import React, { memo, useCallback, useEffect, useRef } from 'react';
import { Header, LeftComponentsHeader } from '@components/navigation/Header/Header';
import { Nav } from '@components/navigation/Nav/Nav';
import { PageTitle } from '@components/navigation/Nav/PageTitle';
import { FormattedMessage } from 'react-intl';
import { RoundButton } from '@components/Button/RoundButton/RoundButton';
import Download from '@mui/icons-material/Download';

export const HelpPage = memo(() => {
    const shadowRootRef = useRef<HTMLDivElement | null>(null);
    const htmlFilePath = '/helpHtml/sch_help.html';
    const importFilePath = '/exportFormats/template_schedule.xlsx';

    const download = useCallback((filePath: string) => {
        window.open(filePath, '_blank');
    }, []);

    /**
     * We can't use an iframe or embed tag because of server's Content-Security-Policy,
     * so we use a hacked up shadow DOM to display the html content.
     * The shadow DOM is needed so that the css styles and html tags don't leak out and affect the rest of the page.
     * We also rewrite the image srcs to be relative to the html file and, finally,
     * we remove the 'a' tags wrapping the images to prevent user from clicking and being redirected
     **/
    useEffect(() => {
        fetch(htmlFilePath)
            .then(response => response.text())
            .then(data => {
                const parser = new DOMParser();
                const htmlDoc = parser.parseFromString(data, 'text/html');
                const baseHref =
                    window.location.origin + htmlFilePath.substring(0, htmlFilePath.lastIndexOf('/')) + '/';
                htmlDoc.querySelectorAll('img').forEach(img => {
                    const src = img.getAttribute('src');
                    if (src && !src.startsWith('http')) {
                        img.setAttribute('src', baseHref + src);
                    }
                    // Kill the 'a' tags wrapping the images
                    const parent = img.parentNode;
                    if (parent && parent.nodeName === 'A') {
                        while (parent.firstChild) {
                            parent?.parentNode?.insertBefore(parent.firstChild, parent);
                        }
                        parent?.parentNode?.removeChild(parent);
                    }
                });
                if (shadowRootRef.current) {
                    const shadowRoot = shadowRootRef.current.attachShadow({ mode: 'open' });
                    shadowRoot.innerHTML = htmlDoc.documentElement.innerHTML;
                }
            });
    }, []);

    return (
        <Box display="flex" flexDirection="column" alignItems="center" width="100%" height="100%" overflow="hidden">
            <Header>
                <LeftComponentsHeader>
                    <Nav>
                        <PageTitle>
                            <FormattedMessage id="nav.title.help" />
                        </PageTitle>
                    </Nav>
                </LeftComponentsHeader>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} m={2} flexShrink={0}>
                    <RoundButton
                        color="secondary"
                        size="medium"
                        variant="contained"
                        onClick={() => download(htmlFilePath)}
                        startIcon={<Download />}
                    >
                        <FormattedMessage id="help.download.quick-guide" />
                    </RoundButton>
                    &emsp;
                    <RoundButton
                        color="secondary"
                        size="medium"
                        variant="contained"
                        onClick={() => download(importFilePath)}
                        startIcon={<Download />}
                    >
                        <FormattedMessage id="help.download.generic-import" />
                    </RoundButton>
                </Box>
            </Header>
            <Box display={'flex'} height={'100%'} width={'80%'} flexDirection={'column'} overflow={'auto'}>
                <StyledManualContainer>
                    <StyledManualShadowDOMDiv ref={shadowRootRef} />
                </StyledManualContainer>
            </Box>
        </Box>
    );
});

const StyledManualContainer = styled('div')`
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(97%);
    margin-top: 10px;
`;
const StyledManualShadowDOMDiv = styled('div')`
    background: white;
    padding: 10px;
    overflow: scroll;
    height: 100%;
`;
